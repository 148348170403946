import {
  NxFormik,
  NxFormikButton,
  NxFormikInput,
  NxFormikMultiSelect,
  NxFormikSelect,
  NxLoader,
  NxRow,
  NxRowPosition,
  NxStack
} from '@nextbank/ui-components';
import {useCommand} from 'command/CommandService';
import {CommandOutputWrapper} from 'command/CommandTypes';
import CustomFieldCategoryTreeEdit from 'custom-field/CustomFieldCategoryTreeEdit';
import CustomFieldCategoryTreeView, {CustomFieldCategoryValue} from 'custom-field/CustomFieldCategoryTreeView';
import {
  CustomFieldCategory,
  CustomFieldDefinition,
  CustomFieldGroup,
  CustomFieldType
} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService from 'custom-field/CustomFieldService';
import {CustomerType} from 'customer/CustomerTypes';
import NxForm from 'form/NxForm';
import NxHeader from 'form/NxHeader';
import NxPage from 'form/NxPage';
import {FormikProps} from 'formik';
import NxFormikBranchMultiselect from 'management/NxFormikBranchMultiselect';
import NxCancelButton from 'NxCancelButton';
import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import NxFormikEditableTree, {NxTreeNode} from 'tree/editableTree/NxFormikEditableTree';
import * as Yup from 'yup';

type CustomFieldDefinitionInput = Omit<CustomFieldDefinition, 'id'>;

interface FormInput {
  name: string;
  group: CustomFieldGroup,
  type: CustomFieldType,
  required: boolean,
  showInReports: boolean,
  availableInBranchIds: number[],
  categories?: NxTreeNode<CustomFieldCategoryValue>[],
  customerTypes?: CustomerType[] | null
}

interface EditCustomFieldDefinitionInput {
  definition: CustomFieldDefinitionInput & {id?: number};
  categories?: CustomFieldCategory[];
}

const CustomFieldEditFormSchema: Yup.SchemaOf<FormInput> = Yup.object().shape({
  name: Yup.string()
    .required()
    .label('Custom Field Name'),
  type: Yup.mixed()
    .required()
    .label('Custom Field Type'),
  group: Yup.mixed()
    .required()
    .label('Custom Field Group'),
  required: Yup.mixed()
    .required()
    .label('Required'),
  showInReports: Yup.mixed()
    .required()
    .label('Required'),
  availableInBranchIds: Yup.array()
    .required()
    .min(1, 'Please select available branches')
    .label('Available In Branches'),
  customerTypes: Yup.array()
    .nullable()
    .when('group', {
      is: 'CUSTOMER',
      then: Yup.array().required()
        .min(1, 'Please select customer types')
    })
    .label('Customer Types'),
  categories: Yup.array()
    .when('type', {
      is: 'CATEGORY',
      then: Yup.array()
        .required()
        .min(1, 'Please add at least one category')
    })
});

const mapCategoryToNode = (category: CustomFieldCategory): NxTreeNode<CustomFieldCategoryValue> => {
  return {
    value: {
      id: category.id,
      label: category.value,
      code: category.code ?? '',
      remarks: category.remarks
    },
    children: category.children ? category.children.map(child => mapCategoryToNode(child)) : []
  };
};

const mapNodeToCategory = (node: NxTreeNode<CustomFieldCategoryValue>,
                           definitionId: number | undefined,
                           parentId: number | undefined): CustomFieldCategory => {
  return {
    id: node.value.id,
    code: node.value.code,
    value: node.value.label,
    parentId,
    definitionId,
    remarks: node.value.remarks,
    enabled: true,
    children: node.children.map(child => mapNodeToCategory(child, definitionId, parentId))
  };
};

const filterDisabledCategories = (categories: CustomFieldCategory[]): CustomFieldCategory[] => {
  if(!categories || categories.length === 0) {
    return [];
  }
  
  categories = categories.filter(c => c.enabled);
  for(const category of categories) {
    category.children = filterDisabledCategories(category.children);
  }
  return categories;
};

const prepareData = async (id: string, group: string): Promise<{
  definition: CustomFieldDefinitionInput & {id?: number},
  categories: CustomFieldCategory[]
}> => {
  const isNew = id === 'new';
  if (isNew) {
    return {
      definition: {
        name: '',
        group,
        required: true,
        showInReports: true,
        enabled: true,
        type: CustomFieldType.TEXT,
        availableInBranchIds: []
      },
      categories: []
    };
  }

  const definition = await customFieldService.readDefinition(Number(id));
  if (definition.type === CustomFieldType.CATEGORY) {
    let categories = await customFieldService.readCategories([definition.id]);
    categories = filterDisabledCategories(categories);

    return {
      definition,
      categories
    };
  }

  return {
    definition,
    categories: []
  };
};

const customerTypes = [{
  label: 'Individual',
  value: 'INDIVIDUAL'
}, {
  label: 'Corporate',
  value: 'CORPORATE'
}, {
  label: 'Group',
  value: 'GROUP'
}];

const customFieldTypes = [{
  label: 'Text',
  value: CustomFieldType.TEXT
}, {
  label: 'Number',
  value: CustomFieldType.NUMBER
}, {
  label: 'Category',
  value: CustomFieldType.CATEGORY
}];

const booleanOptions = [{
  label: 'Yes',
  value: true
}, {
  label: 'No',
  value: false
}];

const CustomFieldDefinitionView = ({group}: {group: CustomFieldGroup}): ReactElement => {
  const {id} = useParams<{id: string}>();
  const execute = useCommand();
  const history = useHistory();
  const [catAndDef, setCatAndDef] = useState<{
    categories: CustomFieldCategory[],
    definition: CustomFieldDefinitionInput & {id?: number}
  }>();
  const isNew = id === 'new';

  useEffect(() => {
    prepareData(id, group)
      .then(({definition, categories}) => {
        setCatAndDef({
          categories,
          definition
        });
      }).catch(e => {
      console.error('Error preparing form data', e);
      throw e;
    });
  }, [id, group]);

  const categoryNodes = useMemo(() => {
    if (!catAndDef) {
      return [];
    }

    const nodes = catAndDef.categories.map(v => mapCategoryToNode(v));
    return [...nodes];
  }, [catAndDef]);

  if (!catAndDef) {
    return <NxLoader/>;
  }

  return <NxPage>
    <NxHeader>{isNew ? 'New custom field' : `Edit ${catAndDef.definition.name}`}</NxHeader>
    <NxFormik<FormInput>
      initialValues={{
        name: catAndDef.definition.name,
        type: catAndDef.definition.type,
        required: catAndDef.definition.required,
        showInReports: catAndDef.definition.showInReports,
        availableInBranchIds: catAndDef.definition.availableInBranchIds,
        customerTypes: catAndDef.definition.customerTypes,
        categories: categoryNodes,
        group: group
      }}
      validationSchema={CustomFieldEditFormSchema}
      onSubmit={async (input: FormInput): Promise<void> => {
        const categories = input.categories?.map(node =>
          mapNodeToCategory(node, catAndDef.definition.id, undefined));
        const response: CommandOutputWrapper<void> = await execute<EditCustomFieldDefinitionInput, void>({
          name: 'EditCustomFieldDefinition',
          input: {
            definition: {
              ...catAndDef.definition,
              name: input.name,
              type: input.type,
              required: input.required,
              showInReports: input.showInReports,
              availableInBranchIds: input.availableInBranchIds,
              customerTypes: input.customerTypes
            },
            categories
          }
        });

        if (!response.approvalRequired) {
          history.goBack();
        }
      }}>
      {({isValid, values, submitForm, isSubmitting}: FormikProps<FormInput>): ReactElement => {
        return <NxForm>
          <NxStack>
            <NxFormikInput label="Name" name="name" disabled={!isNew}/>
            <NxFormikSelect label="Type" options={customFieldTypes} name="type" disabled={!isNew}/>
            <NxFormikSelect label="Required" options={booleanOptions} name="required"/>
            <NxFormikSelect label="Display in report configuration" options={booleanOptions} name="showInReports"/>
            {values.group === 'CUSTOMER' ? <NxFormikMultiSelect label="Customer Types" options={customerTypes}
                                                                name="customerTypes"/> : null}
            <NxFormikBranchMultiselect name="availableInBranchIds" label="Available in branches"/>
            {values.type === CustomFieldType.CATEGORY ? <NxFormikEditableTree<CustomFieldCategoryValue>
                name="categories"
                label="Categories"
                TreeNodeEdit={CustomFieldCategoryTreeEdit}
                TreeNodeView={CustomFieldCategoryTreeView}
                required
              />
              : null
            }
            <NxRow position={NxRowPosition.END}>
              <NxCancelButton/>
              <NxFormikButton
                disabled={!isValid}
                loaded={!isSubmitting}
                onClick={submitForm}
                type="submit">
                Confirm
              </NxFormikButton>
            </NxRow>
          </NxStack>
        </NxForm>;
      }}
    </NxFormik>
  </NxPage>;
};

export default CustomFieldDefinitionView;