import nxModule from 'nxModule';
import _ from 'lodash';

import templateUrl from './atm-terminal-details.template.html';
import {AccountWithLabel, addAccountLabels} from "../../general-ledger/common/gl.utils";
import {IFormController, ILocationService} from "angular";
import GlMappingsService from "components/administration/gl-mappings/gl-mappings.service";
import {AtmTerminal, AtmTerminalBranchCache} from "components/atm/service/atm-terminal-branch.cache.types";
import {NxRouteService} from "routes/NxRouteService";
import {CommandService} from "shared/utils/command/command.types";
import ConfirmationTemplate from "shared/common/confirmationTemplate";

class AtmTerminalCreate {

  private branchId!: number;
  private atmTerminal!: AtmTerminal;
  private atmTerminalForm!: IFormController;
  private ledgerAccounts!: AccountWithLabel[];
  private readonly ledgerAccountSelectConfig = {
    placeholder: 'Select ledger account',
    searchField: 'label',
    valueField: 'fullCode',
    labelField: 'label',
    maxItems: 1
  };

  constructor(private $route: NxRouteService,
              private $location: ILocationService,
              private confirmationTemplate: ConfirmationTemplate,
              private command: CommandService,
              private glMappingsService: GlMappingsService,
              private atmTerminalBranchCache: AtmTerminalBranchCache) {
  }

  async $onInit() {
    this.branchId = Number(this.$route.current.params['branchId']);
    const atmTerminalId = Number(this.$route.current.params['atmId']);
    const atmTerminals = await this.atmTerminalBranchCache.withParam(this.branchId).toPromise();
    this.atmTerminal = atmTerminals.find(atm => atm.id === atmTerminalId)!;
    const accounts = await this.glMappingsService.accounts.toPromise();
    const assetAccounts = _.filter(accounts, (glAccount) => ['ASSET', 'LIABILITY'].includes(glAccount.accountGroup));
    this.ledgerAccounts = addAccountLabels(assetAccounts);
  }

  async createOrUpdate() {
    const request = _.cloneDeep(this.atmTerminal);
    if (this.atmTerminal.id) {
      await this.update(request);
    } else {
      await this.create(request);
    }
  }

  async update(request: unknown) {
    const confirmed = await this.confirmationTemplate({'question': 'Update ATM Terminal?'});
    if (confirmed) {
      await this.command.execute('UpdateAtmTerminal', request).toPromise();
      this.refreshCacheAndRedirect();
    }
  }

  async create(request: AtmTerminal) {
    const confirmed = await this.confirmationTemplate({
      question: 'Create ATM Terminal?',
      details: [
        {label: 'Terminal ID', description: this.atmTerminal.terminalId},
        {label: 'Ledger account code', description: this.atmTerminal.ledgerAccountCode},
        {label: 'Street', description: this.atmTerminal.street},
        {label: 'Barangay', description: this.atmTerminal.barangay},
        {label: 'City', description: this.atmTerminal.city},
        {label: 'Province', description: this.atmTerminal.province},
        {label: 'Postal code', description: this.atmTerminal.postalCode}
      ]
    });

    if (confirmed) {
      await this.command.execute('CreateAtmTerminal', {
        ...request,
        branchId: this.branchId
      }).toPromise();
      this.refreshCacheAndRedirect();
    }
  }

  refreshCacheAndRedirect() {
    this.atmTerminalBranchCache.withParam(this.branchId).refetch();
    this.redirectBack();
  }

  redirectBack() {
    this.$location.path(`/admin/atm-terminals/${this.branchId}`);
  }
}

nxModule.component('atmTerminalDetails', {
  templateUrl: templateUrl,
  controller: AtmTerminalCreate
});