import {CustomerType} from 'components/customer/profile/customer-profile.types';
import {AtLeastOne} from 'shared/utils/AtleastOne';

export interface CustomFieldCategory {
  id?: number;
  parentId?: number;
  definitionId?: number;
  value: string;
  code?: string;
  remarks: string;
  enabled: boolean;
  children: CustomFieldCategory[];
}

export interface CustomFieldCategoryDetails {
  id?: number;
  definitionId: number;
  categoryTypeName: string;
  categoryId: number;
  categoryName: string;
  categoryParentId: number;
  children: CustomFieldCategoryDetails[];
}

export enum CustomFieldType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  CATEGORY = 'CATEGORY'
}

export interface CustomFieldDefinition {
  id: number;
  name: string;
  type: CustomFieldType,
  customerTypes?: CustomerType[] | null
  enabled: boolean;
  showInReports: boolean;
  group: string;
  availableInBranchIds: number[];
  required: boolean;
}

export interface CustomFieldDefinitionCriteria {
  customerType?: CustomerType
  groups: string[];
  enabled?: boolean;
}

export enum CustomFieldGroup {
  LOAN = 'LOAN',
  ACCOUNT = 'ACCOUNT',
  DEPOSIT = 'DEPOSIT',
  CUSTOMER = 'CUSTOMER',
}

export type CustomFieldValueCriteria = AtLeastOne<{
  productId: number;
  customerId: number;
}>
