import {productStatus} from 'constants/product';
import nxModule from 'nxModule';
import _ from 'lodash';
import {
  amortizationHooks,
  chargeCollectionTypes,
  contractualSavingFees,
  equallyCyclicAmortizedFeeCalculationMethods,
  feeAmortizationTypes,
  loanArbitraryFees,
  loanCreateCalculationMethods,
  loanCreationTypes,
  loanCustomPreterminationFeesCalculationMethods,
  loanMaturityCalculationMethods,
  loanPastDueCalculationMethods,
  penaltyFees,
  penaltyChargeCollectionTypes
} from 'constants/loan';
import {agentChargeCollectionTypes, feeTypes, roundingScales} from 'constants/fee';

import './custom-fee.style.less';
import {
  loanApplyOnProductHooks,
  loanApplyOnProductHooksMaturity,
  loanApplyOnProductHooksPastDue,
  loanApplyOnProductHooksPretermination,
  loanArbitraryFeesApplyOnHooks
} from './constants';
import {ACCOUNT_CUSTOM_FEE_HOOKS, ACCOUNT_FEE_CALCULATION_METHODS} from "constants/account";
import {EXTERNAL_TRANSFER_CHANNELS} from '../../service/external-transfer.types';
import systemPropertyService from '../../../../react/system/systemPropertyService';
import {depositCustomCalculationMethods, depositDocStampCalculationMethods} from 'constants/deposit';

const templateUrl = require('./custom-fee.template.html');

class CustomFee {
  constructor($scope) {
    this.$scope = $scope;
    //constants
    this.loanCreationTypes = loanCreationTypes;
    this.loanCalculationMethods = loanCreateCalculationMethods;
    this.depositCalculationMethods = depositCustomCalculationMethods;
    this.accountCalculationMethods = ACCOUNT_FEE_CALCULATION_METHODS;
    this.allChargeCollectionTypes = chargeCollectionTypes;
    this.agentChargeCollectionTypes = agentChargeCollectionTypes;
    this.allAmortizationsChargeCollectionTypes = [chargeCollectionTypes.find(ct => ct === 'PROPORTIONAL_TO_DAYS_DELAYED')];
    this.feeTypes = feeTypes;
    this.roundingScales = roundingScales;
    this.depositAgeType = 'fixedValue';
    this.penaltyDaysCalculationTypes = [
      {
        label: 'Days late',
        value: 'DAYS_LATE'
      },
      {
        label: 'Loan status',
        value: 'STATUS_CHANGE'
      }
    ];
    this.externalTransferTypes = [
      {
        label: 'Any',
        value: null
      },
      {
        label: 'Regular',
        value: 'P2P'
      },
      {
        label: 'Regular with QR code',
        value: 'QR_P2P'
      },
      {
        label: 'Merchant transfer',
        value: 'QR_P2M'
      }
    ];
    this.penaltyStatuses = [
      {
        label: 'Active',
        value: 'ACTIVE'
      },
      {
        label: 'Past due performing',
        value: 'PAST_DUE_PERFORMING'
      }, {
        label: 'Past due non-performing',
        value: 'PAST_DUE_NON_PERFORMING'
      }, {
        label: 'Past due litigation',
        value: 'PAST_DUE_LITIGATION'
      }, {
        label: 'Past due write off',
        value: 'PAST_DUE_WRITE_OFF'
      }
    ];
    let deductionParts = [];
    this.externalTransferOverDraftStrategy = 'ERROR';
    this.penaltyChargeCollectionTypes = penaltyChargeCollectionTypes;
  }

  async $onInit() {
    const channels = systemPropertyService.getProperty('PARTNER_CHANNELS');
    this.accountPartnerOperationProviders = channels.split(', ');
    const allPredicates = this.feeDefinition.predicates || [];
    this.externalTransferChannels = EXTERNAL_TRANSFER_CHANNELS;
    this.$scope.$watch('$ctrl.branches', () => {
      if (this.branches) {
        this.branchesIds = this.predicatesToValues(allPredicates, 'BranchIdFeePredicate', () => this.branches.map(b => b.id));
      }
    });

    this.$scope.$watch('$ctrl.allFeeDefinitions', () => {
      this.getAvailablePercentageOfDeductionFees();
    });

    if (this.feeDefinition.feeTypes) {
      this.feeTypes = this.feeDefinition.feeTypes;
    }

    if (!this.feeDefinition.predicates) {
      this.feeDefinition.predicates = [];
    }

    if (!this.feeDefinition.extraOptions) {
      this.feeDefinition.extraOptions = {};
    }

    this.savedDeductedPartNames = this.feeDefinition.extraOptions?.DEDUCTION_PART?.map(entry => entry.name) ?? [];

    this.loanApplyYearly = this.feeDefinition.extraOptions?.LOAN_APPLY_YEARLY;

    if (this.feeDefinition.extraOptions?.LOAN_BOARD
      || this.feeDefinition.extraOptions?.WITHHOLDING_TAX_BOARD
      || this.feeDefinition.extraOptions?.DEPOSIT_DOC_STAMP_BOARD
      || this.feeDefinition.extraOptions?.ACCOUNT_FEE_BOARD
      || this.feeDefinition.extraOptions?.AGENT_FEE_BOARD
      || this.feeDefinition.extraOptions?.PLEDGE_DEPOSIT_DEVIATION_INTERVAL_FEE_BOARD) {
      this.useFeeBoard = true;
    }

    //Loan specific properties
    if (this.productGroup === 'LOAN') {
      this.applyOnProductHooks = loanApplyOnProductHooks;
      this.amortizationHooks = amortizationHooks;
      this.loanArbitraryFeesApplyOnHooks = loanArbitraryFeesApplyOnHooks;
      //extract selected values from predicates
      if (this.feeDefinition.feeClass !== 'CUSTOM' && this.feeDefinition.validCalculationMethods) {
        this.loanCalculationMethods = this.feeDefinition.validCalculationMethods;
      } else {
        this.updateCalculationMethods();
      }

      if (!this.feeDefinition.calculateOn && this.isLoanDocStamp()) {
        this.feeDefinition.calculateOn = 'CREATE';
      }

      this.selectedLoanCreationTypes = this.predicatesToValues(allPredicates, 'LoanCreationTypeIdFeePredicate',
        () => this.loanCreationTypes.map(t => t.value));

      this.loanArbitraryFeesApplyOnApplicable = loanArbitraryFees.includes(this.feeDefinition.feeClass);
      this.feeAmortizationTypes = feeAmortizationTypes;
      this.feeClasses = ['CUSTOM'];

      if (this.feeDefinition.feeClass === 'INSURANCE_FEE') {
        // loan insurance account
        const {insuranceFeeDeductionStrategy, insuranceSavingsAccountId} = this.feeDefinition.insuranceFeeOptions;
        if (insuranceFeeDeductionStrategy === 'TRANSFER_TO_ACCOUNT') {
          this.feeDefinition.insuranceFeeOptions.insuranceSavingsAccount = this.loanInsuranceAccounts.find(acc => acc.id === insuranceSavingsAccountId)
        }
      }
    }

    //Deposit specific properties
    if (this.productGroup === 'DEPOSIT') {
      this.depositAgeFixedValue = this.predicatesToValues(allPredicates, 'DepositAgeFeePredicate',
        () => null);

      if (this.depositAgeFixedValue !== null) {
        this.depositAgeType = 'fixedValue';
      }

      this.depositAgePercentage = this.predicatesToValues(allPredicates, 'DepositAgeFeePercentagePredicate',
        () => null);

      if (this.depositAgePercentage !== null) {
        this.depositAgeType = 'percentage';
      }

      if (this.feeDefinition.feeClass === 'CUSTOM') {
        // Deposits customs fees supports FIXED, PERCENTAGE type
        this.feeTypes = ['FIXED', 'PERCENTAGE'];
        this.depositCalculationMethods = depositCustomCalculationMethods;
      } else {
        // Currently Deposits doc-stamp and WITHHOLDING_TAX fees supports only percentage value
        this.feeTypes = ['PERCENTAGE'];
        this.depositCalculationMethods = depositDocStampCalculationMethods;
      }
      this.feeClasses = this.isWithholdingTaxFee() ? ['WITHHOLDING_TAX'] : ['CUSTOM', 'DOC_STAMP'];
    }
    //Account specific properties
    if (this.productGroup === 'ACCOUNT') {
      if (this.feeDefinition?.id && this.isExternalTransfer()) {
        this.externalTransferOverDraftStrategy = this.feeDefinition.overdraftStrategy;
        this.transferTypePredicate = this.getPredicateOfType('ExternalTransferTypeFeePredicate')?.value
      }
    }

  }

  getMemoizedFeeTypes = _.memoize((feeClass, productGroup) => {
    if (productGroup === 'ACCOUNT' && ['AGENT_DEPOSIT', 'AGENT_WITHDRAWAL'].includes(this.feeDefinition.feeClass)) {
      return ['FIXED'];
    }

    if (productGroup === 'DEPOSIT' && feeClass !== 'CUSTOM') {
      // Deposits other fees supports only PERCENTAGE type
      return ['PERCENTAGE'];
    }

      return ['FIXED', 'PERCENTAGE']
    },
    // Resolver function is required for _.memoize to cache basing on all arguments instead of only one
    (...args) => _.values(args).join("_")
  );

  getFeeTypes() {
    return this.getMemoizedFeeTypes(this.feeDefinition.feeClass, this.productGroup);
  }

  getLoanApplyOnProductHooks = _.memoize(calculateOn => {
    if (calculateOn === 'CREATE') {
      return loanApplyOnProductHooks;
    }

    if (calculateOn === 'LOAN_PRETERMINATION') {
      return loanApplyOnProductHooksPretermination;
    }

    if (calculateOn === 'LOAN_PAST_DUE') {
      return loanApplyOnProductHooksPastDue;
    }

    if (calculateOn === 'LOAN_MATURITY') {
      return loanApplyOnProductHooksMaturity;
    }

    return [];
  });

  getDepositApplyOnProductHooks = _.memoize(calculateOn => {
    if (!calculateOn) {
      return [];
    }

    if (calculateOn === 'DEPOSIT_PRETERMINATION') {
      return ['DEPOSIT_PRETERMINATION'];
    }

    if (calculateOn === 'DEPOSIT_CERTIFICATION') {
      return ['DEPOSIT_PRETERMINATION', 'DEPOSIT_CERTIFICATION', 'DEPOSIT_BEFORE_ROLLOVER', 'DEPOSIT_AFTER_ROLLOVER'];
    }

    if (['DEPOSIT_BEFORE_MATURITY', 'DEPOSIT_AFTER_ROLLOVER'].includes(calculateOn)) {
      return ['DEPOSIT_BEFORE_MATURITY', 'DEPOSIT_BEFORE_ROLLOVER', 'DEPOSIT_AFTER_ROLLOVER'];
    }

    if (calculateOn === 'PLEDGE_DEPOSIT_DEVIATION') {
      return ['DEPOSIT_PLACEMENT'];
    }

    return ['DEPOSIT_PRETERMINATION', 'DEPOSIT_CERTIFICATION', 'DEPOSIT_AFTER_ROLLOVER'];
  });

  getApplyOnProductHooks() {
    if (this.productGroup === 'LOAN') {
      return this.getLoanApplyOnProductHooks(this.feeDefinition.calculateOn);
    }

    if (this.productGroup === 'DEPOSIT') {
      return this.getDepositApplyOnProductHooks(this.feeDefinition.calculateOn);
    }

    if (this.productGroup === 'ACCOUNT') {
      return ACCOUNT_CUSTOM_FEE_HOOKS;
    }

    console.error('Unknown product group', this.productGroup);
    return null;
  }

  shouldDisableCalculateOn() {
    return this.feeDefinition.forAccretion
      || (this.feeDefinition.calculateOn === 'CREATE' && ['PERCENTAGE_BASED_ON_YEAR_TERM', 'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE'].includes(this.feeDefinition.calculationMethod));
  }

  shouldDisableApplyOn() {
    return this.feeDefinition.forAccretion
      || (this.feeDefinition.applyOn === 'LOAN_RELEASE' && ['PERCENTAGE_BASED_ON_YEAR_TERM', 'PERCENTAGE_OF_DEDUCTION_COLLECTED_ON_RELEASE', 'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE'].includes(this.feeDefinition.calculationMethod));
  }

  getChargeCollectionTypes() {
    if (this.feeDefinition.feeClass === 'PENALTY') {
      return this.penaltyChargeCollectionTypes;
    }

    switch (this.feeDefinition.applyOn) {
      case 'ALL_AMORTIZATIONS':
        return this.allAmortizationsChargeCollectionTypes;
      case 'LAST_AMORTIZATION':
        return this.allChargeCollectionTypes;
    }

    console.error('Unknown apply on', this.feeDefinition.applyOn);
    return null;
  }

  getLoanCalculateOnProductHooks = _.memoize(feeClass => {
    return ['CREATE', 'LOAN_PRETERMINATION', 'LOAN_MATURITY', 'LOAN_PAST_DUE'];
  });

  getDepositCalculateOnProductHooks = _.memoize(feeClass => {
    if (!feeClass) {
      return [];
    }

    if (feeClass === 'DOC_STAMP') {
      return ['DEPOSIT_PRETERMINATION',
        'DEPOSIT_CERTIFICATION',
        'DEPOSIT_BEFORE_MATURITY',
        'DEPOSIT_BEFORE_ROLLOVER',
        'DEPOSIT_AFTER_ROLLOVER'];
    }

    return ['DEPOSIT_PRETERMINATION', 'PLEDGE_DEPOSIT_DEVIATION'];
  });

  getCalculateOnProductHooks() {
    if (this.productGroup === 'LOAN') {
      if (['PERCENTAGE_BASED_ON_YEAR_TERM', 'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE'].includes(this.feeDefinition.calculationMethod)) {
        return ['CREATE'];
      }
      return this.getLoanCalculateOnProductHooks(this.feeDefinition.feeClass);
    }

    if (this.productGroup === 'DEPOSIT') {
      return this.getDepositCalculateOnProductHooks(this.feeDefinition.feeClass);
    }

    console.error('Unknown product group', this.productGroup);
    return null;
  }

  getInsuranceFeeDeductionStrategies() {
    const filter = this.feeDefinition.applyOn === 'LOAN_RELEASE' ? (() => true) : (s => s !== 'DEDUCT_FROM_RELEASE_AMOUNT');
    return this.feeDefinition.insuranceFeeOptions.insuranceFeeDeductionStrategies.filter(filter);
  }

  clearAccretionSettings() {
    if (this.productGroup !== 'LOAN' || this.feeDefinition.feeClass !== 'CUSTOM') {
      return;
    }

    this.updateIncludedInEirComputation();

    if (!this.feeDefinition.includedInEirComputation || this.feeDefinition.applyOn !== 'LOAN_RELEASE') {
      this.feeDefinition.forAccretion = false;
      this.feeDefinition.alwaysIncludeOnDiscountCharges = false;
    }
  }

  changeForAccretion() {
    if (!this.feeDefinition.forAccretion) {
      this.feeDefinition.alwaysIncludeOnDiscountCharges = false;
    }
  }

  clearDeductionPartSettings() {
    if (this.productGroup !== 'LOAN' || this.feeDefinition.feeClass !== 'CUSTOM') {
      return;
    }

    this.deductionParts = [];

    if (this.feeDefinition.extraOptions?.DEDUCTION_PART) {
      this.feeDefinition.extraOptions.DEDUCTION_PART = null;
    }
  }

  updateFeeDefinition() {
    this.clearAmortizedFeeConfig();
    this.updateCalculationMethods();
    this.updateDepositAllowedStatuses();
  }

  updateCalculationMethods() {
    if (this.productGroup !== 'LOAN') {
      // leave
      return;
    }

    switch (this.feeDefinition.calculateOn) {
      case 'CREATE':
        this.loanCalculationMethods = this.feeDefinition.feeAmortizationType === 'EQUALLY_CYCLIC'
          ? equallyCyclicAmortizedFeeCalculationMethods
          : loanCreateCalculationMethods;
        break;
      case 'LOAN_MATURITY':
        this.loanCalculationMethods = loanMaturityCalculationMethods;
        this.feeDefinition.applyOn = loanApplyOnProductHooksMaturity[0];
        break;
      case 'LOAN_PRETERMINATION':
        this.loanCalculationMethods = loanCustomPreterminationFeesCalculationMethods;
        break;
      case 'LOAN_PAST_DUE':
        this.loanCalculationMethods = loanPastDueCalculationMethods;
        break;
      default:
        console.error(`Invalid calculate on ${this.feeDefinition.calculateOn} hook provided.`);
        break;
    }
    this.clearAccretionSettings();
    this.clearDeductionPartSettings();
    this.clearDeductFromAmortizationPrincipal();
  }

  updateDepositAllowedStatuses() {
    if (this.productGroup !== 'DEPOSIT') {
      return;
    }

    if (this.feeDefinition.calculateOn === 'DEPOSIT_BEFORE_ROLLOVER') {
      this.feeDefinition.calculateOnStatuses = ['ACTIVE', 'INACTIVE', 'MATURE'];
      this.feeDefinition.applyOnStatuses = ['ACTIVE', 'INACTIVE', 'MATURE'];
    } else {
      this.feeDefinition.calculateOnStatuses = ['ACTIVE'];
      this.feeDefinition.applyOnStatuses = ['ACTIVE'];
    }
  }

  predicatesToValues(allPredicates, predicateType, defaultValues) {
    let predicates = allPredicates.filter(p => p.type === predicateType);
    if (predicates.length === 1) {
      return predicates[0].value;
    } else {
      return defaultValues();
    }
  }

  isCustomizableFee() {
    return this.feeDefinition.feeClass === 'CUSTOM' || this.isDepositDocStamp();
  }

  isDepositDocStamp() {
    return this.productGroup === 'DEPOSIT' && this.feeDefinition.feeClass === 'DOC_STAMP';
  }

  isLoanDocStamp() {
    return this.productGroup === 'LOAN' && this.feeDefinition.feeClass === 'DOC_STAMP';
  }

  feeClassChange() {
    if (this.productGroup === 'DEPOSIT') {
      this.clearBoards();
      this.useFeeBoard = false;

      if (this.feeDefinition.feeClass === 'DOC_STAMP') {
        this.depositCalculationMethods = depositDocStampCalculationMethods;
        this.feeDefinition.predicates = [];
      } else if (this.feeDefinition.feeClass === 'CUSTOM') {
        this.depositCalculationMethods = depositCustomCalculationMethods;
      }
    }
  }

  feeTypeChange() {
    if (this.feeDefinition.feeType === 'FIXED') {
      this.feeDefinition.calculationMethod = 'FIXED_AMOUNT';
    } else if (this.feeDefinition.feeType === 'PERCENTAGE' && this.isAccountTaggedMemoFee()) {
      // tagged memo fees only support this method.
      this.feeDefinition.calculationMethod = 'PERCENTAGE_OF_OPERATION';
    } else if (this.feeDefinition.feeType === 'PERCENTAGE') {
      this.feeDefinition.calculationMethod = null;
    }

    this.feeDefinition.fixedAmount = 0;
    this.feeDefinition.percentageAmount = 0;
  }

  useFeeBoardChange() {
    if (this.useFeeBoard) {
      if (this.productGroup === 'LOAN') {
        this.feeDefinition.extraOptions.LOAN_BOARD = {cells: []};
      }

      if (this.productGroup === 'DEPOSIT' && this.isWithholdingTaxFee()) {
        this.feeDefinition.extraOptions.WITHHOLDING_TAX_BOARD = {cells: []};
      }

      if (this.productGroup === 'DEPOSIT' && this.feeDefinition.feeClass === 'DOC_STAMP') {
        this.feeDefinition.extraOptions.DEPOSIT_DOC_STAMP_BOARD = {cells: []};
      }

      if (this.productGroup === 'ACCOUNT' && this.feeDefinition.feeClass === 'CUSTOM') {
        this.feeDefinition.extraOptions.ACCOUNT_FEE_BOARD = {cells: []};
      }

      if (this.productGroup === 'ACCOUNT' && ['AGENT_DEPOSIT', 'AGENT_WITHDRAWAL'].includes(this.feeDefinition.feeClass)) {
        this.feeDefinition.extraOptions.AGENT_FEE_BOARD = {cells: []};
      }

      if (this.isPledgeDepositDeviationIntervalFeeBoardShown()) {
        this.feeDefinition.extraOptions.PLEDGE_DEPOSIT_DEVIATION_INTERVAL_FEE_BOARD = {cells: []};
      }

      //ignore fee-definition values and use the boards definition
      this.feeDefinition.percentageAmount = 0;
      this.feeDefinition.fixedAmount = 0;
    } else {
      this.clearBoards();
    }
  }

  clearBoards() {
    this.feeDefinition.extraOptions.LOAN_BOARD = null;
    this.feeDefinition.extraOptions.WITHHOLDING_TAX_BOARD = null;
    this.feeDefinition.extraOptions.DEPOSIT_DOC_STAMP_BOARD = null;
    this.feeDefinition.extraOptions.ACCOUNT_FEE_BOARD = null;
    this.feeDefinition.extraOptions.AGENT_FEE_BOARD = null;
    this.feeDefinition.extraOptions.PLEDGE_DEPOSIT_DEVIATION_INTERVAL_FEE_BOARD = null;
  }

  isPledgeDepositDeviationIntervalFeeBoardShown() {
    return this.useFeeBoard
      && this.productGroup === 'DEPOSIT'
      && this.feeDefinition.feeClass === 'CUSTOM'
      && this.feeDefinition.calculateOn === 'PLEDGE_DEPOSIT_DEVIATION';
  }

  selectedValuesToPredicate(predicateType, allValues, selectedValues) {
    //if nothing selected
    if (!selectedValues || selectedValues.length === 0) {
      return {
        type: predicateType,
        value: allValues,
        compareMode: 'NOT_CONTAINS'
      };
      //for all selected
    } else if (allValues.length === selectedValues.length) {
      return [];
    } else if (allValues.length !== selectedValues.length) {
      return {
        type: predicateType,
        value: selectedValues,
        compareMode: 'CONTAINS'
      };
    }
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'BranchIdFeePredicate')
      .concat(branchIdPredicate);
  }

  branchesIdsChange() {
    let allBranchesId = this.branches.map(b => b.id);
    let predicate = this.selectedValuesToPredicate('BranchIdFeePredicate', allBranchesId, this.branchesIds);
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'BranchIdFeePredicate')
      .concat(predicate);
  }

  loanCreationTypesChange() {
    let predicate = this.selectedValuesToPredicate('LoanCreationTypeIdFeePredicate', this.loanCreationTypes, this.selectedLoanCreationTypes);
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'LoanCreationTypeIdFeePredicate')
      .concat(predicate);
  }

  onApplyOnChange() {
    this.updateAccountCustomFee();
    this.updateExtraOptions();
    this.clearDeductFromAmortizationPrincipal();
    this.clearAmortizedFeeConfig();
  }

  updateAccountCustomFee() {
    if (this.productGroup !== 'ACCOUNT') {
      // I am the terminator!
      return;
    }

    // currently, account fees are always calculated and applied at the same time.
    this.feeDefinition.calculateOn = this.feeDefinition.applyOn;

    this.updateAccountEarlyClosureFee();
    this.updatedAccountTaggedMemo();
    this.updateExternalTransfer();
    this.appendDefaultAccountFeeProperties();
  }

  updateAccountEarlyClosureFee() {

    if (!this.isAccountEarlyClosureFee()) {
      // I am the terminator!
      return;
    }

    this.feeDefinition.feeType = 'FIXED';
    this.feeDefinition.calculationMethod = 'FIXED_AMOUNT';
    this.feeDefinition.percentageAmount = null;
    this.appendDefaultAccountFeeProperties();

    this.feeDefinition.predicates = [{
      compareMode: 'LESS_THAN_OR_EQUAL',
      type: 'AccountAgeFeePredicate',
      value: null
    }];
  }

  updatedAccountTaggedMemo() {
    if (!this.isAccountTaggedMemoFee()) {
      // I am the terminator!
      return;
    }

    this.feeDefinition.predicates = [{
      compareMode: 'EQUAL',
      type: 'AccountOperationExternalTagFeePredicate',
      value: null
    }];
  }

  onExternalTransferChannelChange() {
    if (!this.isExternalTransfer()) {
      return;
    }
    // The predicate only applies for Instapay transfers. Null predicate value means no restriction.
    if (this.transferTypePredicate == null || this.getPredicateOfType('ExternalTransferFeePredicate')?.value !== 'INSTAPAY') {
      if (this.getPredicateOfType('ExternalTransferTypeFeePredicate')) {
        _.remove(this.feeDefinition.predicates, el => el.type === 'ExternalTransferTypeFeePredicate');
      }
      return;
    }
    // Add predicate for transfer type if not present
    const transferTypePredicate = this.getPredicateOfType('ExternalTransferTypeFeePredicate');
    console.log(transferTypePredicate)
    if (!transferTypePredicate) {
      this.feeDefinition.predicates.push({
        compareMode: 'EQUAL',
        type: 'ExternalTransferTypeFeePredicate',
        value: this.transferTypePredicate
      });
    } else {
      transferTypePredicate.value = this.transferTypePredicate;
    }
  }

  getPredicateOfType(predicateType) {
    return this.feeDefinition.predicates.find(p => p.type === predicateType)
  }

  updateExternalTransfer() {
    if (!this.isExternalTransfer()) {
      return;
    }

    this.feeDefinition.predicates = [{
      compareMode: 'EQUAL',
      type: 'ExternalTransferFeePredicate',
      value: null
    }];
  }

  appendDefaultAccountFeeProperties() {
    this.setOverDraftStrategy();
    this.feeDefinition.calculateOnStatuses = ['ACTIVE', 'INACTIVE', 'PENDING'];
    this.feeDefinition.applyOnStatuses = ['ACTIVE', 'INACTIVE', 'PENDING'];
    this.feeDefinition.roundingScale = 'CENTAVO';
  }

  updateExtraOptions() {
    if (this.isLoanApplyYearlyEnabled()) {
      this.feeDefinition.extraOptions.LOAN_APPLY_YEARLY = this.loanApplyYearly;
    } else {
      this.loanApplyYearly = null;
      delete this.feeDefinition.extraOptions.LOAN_APPLY_YEARLY;
    }

    if (!['PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR', 'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE', 'ROUNDED_PERCENTAGE_OF_ORIGINAL_PRINCIPAL_WITH_CUSTOM_DIVISOR'].includes(this.feeDefinition.calculationMethod)) {
      //cleanup extra options to make sure it is only applicable for selected calculation methods
      delete this.feeDefinition.extraOptions.DIVISOR;
    }

    if (['PERCENTAGE_BASED_ON_YEAR_TERM', 'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE'].includes(this.feeDefinition.calculationMethod)) {
      this.feeDefinition.applyOn = 'LOAN_RELEASE';
    }
    if (this.productGroup === 'DEPOSIT' && this.feeDefinition.feeClass === 'CUSTOM' && this.feeDefinition.calculateOn !== 'DEPOSIT_PRETERMINATION') {
      this.depositAgeType = null;
      this.depositAgeFixedValue = null;
      this.depositAgePercentage = null;
      this.feeDefinition.predicates = null;
    }
    this.clearAccretionSettings();
    this.getAvailablePercentageOfDeductionFees();
  }

  isLoanApplyYearlyEnabled() {
    return this.feeDefinition.feeClass === 'CUSTOM'
      && this.feeDefinition.calculateOn === 'CREATE'
      && this.feeDefinition.applyOn === 'LOAN_RELEASE'
      && ['PERCENTAGE_BASED_ON_YEAR_TERM', 'PERCENTAGE_BASED_ON_YEAR_OUTSTANDING_BALANCE'].includes(this.feeDefinition.calculationMethod);
  }

  showIncludedInEir() {
    return this.productGroup === 'LOAN'
      && (loanArbitraryFees.includes(this.feeDefinition.feeClass) || this.feeDefinition.feeClass === 'CUSTOM')
      && this.feeDefinition.applyOn === 'LOAN_RELEASE'
  }

  updateIncludedInEirComputation() {
    if (this.feeDefinition.applyOn !== 'LOAN_RELEASE') {
      this.feeDefinition.includedInEirComputation = false;
    }
  }

  depositAgeChange() {
    const predicate = this.depositPredicate();
    this.feeDefinition.predicates = this.feeDefinition.predicates
      .filter(p => p.type !== 'DepositAgeFeePredicate' && p.type !== 'DepositAgeFeePercentagePredicate')
      .concat(predicate);
  }

  depositPredicate() {
    if (this.depositAgeType === 'fixedValue') {
      return {
        type: 'DepositAgeFeePredicate',
        value: this.depositAgeFixedValue,
        compareMode: 'LESS_THAN_OR_EQUAL'
      };
    } else {
      return {
        type: 'DepositAgeFeePercentagePredicate',
        value: this.depositAgePercentage,
        compareMode: 'LESS_THAN_OR_EQUAL'
      };
    }
  }

  canFeeUseBoard() {
    const fd = this.feeDefinition;
    const allowedFeeClasses = {
      'LOAN': ['CUSTOM', 'DOC_STAMP', 'CBU', 'PF', 'TP', 'PENALTY'],
      'DEPOSIT': fd.calculateOn === 'PLEDGE_DEPOSIT_DEVIATION' ? ['CUSTOM'] : ['WITHHOLDING_TAX', 'DOC_STAMP'],
      'ACCOUNT': ['AGENT_DEPOSIT', 'AGENT_WITHDRAWAL', 'CUSTOM']
    };
    const allowed = allowedFeeClasses[this.productGroup];
    return allowed && allowed.includes(fd.feeClass) && fd.feeAmortizationType !== 'EQUALLY_CYCLIC';
  }

  isWithholdingTaxFee() {
    return this.feeDefinition.feeClass === 'WITHHOLDING_TAX';
  }

  clearDeductFromAmortizationPrincipal() {
    if (!this.shouldShowDeductFromPrincipal()) {
      this.feeDefinition.extraOptions.DEDUCT_FROM_AMORTIZATION_PRINCIPAL = null;
    }
  }

  shouldShowDeductFromPrincipal() {
    return this.productGroup === 'LOAN'
      && this.feeDefinition.feeClass === 'CUSTOM'
      && this.feeDefinition.calculateOn === 'CREATE'
      && this.feeDefinition.applyOn === 'LOAN_PAYMENT';
  }

  shouldShowPenaltyCalculationType() {
    return this.feeDefinition.feeClass === 'PENALTY';
  }

  isPenaltyCalculationTypeValid() {
    return this.feeDefinition.feeClass !== 'PENALTY' || !this.useFeeBoard  || this.feeDefinition.extraOptions.PENALTY_DAYS_CALCULATION_TYPE !== 'STATUS_CHANGE';
  }

  shouldShowCalculateOnStatus() {
    return this.feeDefinition.feeClass === 'PENALTY'
      && this.feeDefinition.extraOptions.PENALTY_DAYS_CALCULATION_TYPE === 'STATUS_CHANGE';
  }

  shouldShowVisibilitySetting() {
    return this.productGroup === 'LOAN'
      && [...loanArbitraryFees, 'PENALTY', 'PENALTY_MATURITY', 'CUSTOM'].includes(this.feeDefinition.feeClass);
  }

  shouldDisableCreatedOn() {
    return this.isLoanDocStamp() || this.feeDefinition.calculationMethod === 'PERCENTAGE_OF_DEDUCTION_COLLECTED_ON_RELEASE';
  }

  getAvailablePercentageOfDeductionFees() {
    if (this.allFeeDefinitions && this.feeDefinition.calculationMethod === 'PERCENTAGE_OF_DEDUCTION_COLLECTED_ON_RELEASE') {
      //  filter all valid based fees for deduction
      this.percentageOfDeductionApplicableFees = _.filter(this.allFeeDefinitions, fee => fee.calculateOn === 'CREATE'
        && fee.applyOn === 'LOAN_RELEASE'
        && fee.calculationMethod !== 'PERCENTAGE_OF_DEDUCTION_COLLECTED_ON_RELEASE'
        && fee.feeName !== this.feeDefinition.feeName
        && !contractualSavingFees.includes(fee.feeName.toUpperCase()) // should not be a contractual saving fee
        && !penaltyFees.includes(fee.feeClass)); // should not be penalty fee

      this.deductionParts = _.map(this.percentageOfDeductionApplicableFees, fee => Object.assign({
        name: fee.feeName,
        feeDefinitionId: fee.id,
        feeClass: fee.feeClass
      }));

      // Include Advance interest RR-2 and Interest as choices if loan
      if (this.productGroup === 'LOAN') {
        this.deductionParts.push({
          name: 'Advance Interest',
          feeDefinitionId: null,
          feeClass: null
        }, {
          name: 'Total Interest',
          feeDefinitionId: null,
          feeClass: null
        });
      }

      const deductionParts = this.feeDefinition.extraOptions?.DEDUCTION_PART ?? this.deductionParts;
      this.selectedDeductionParts = deductionParts
        .map(fd => fd.name)
        .filter(feeName => this.savedDeductedPartNames.includes(feeName));

      this.updateSelectedDeductionFees();
      this.feeDefinition.applyOn = 'LOAN_RELEASE';
    }
  }

  updateSelectedDeductionFees() {
    const deductionPart = _.filter(this.deductionParts, fee =>
      this.selectedDeductionParts.includes(fee.name)
    );
    this.feeDefinition.extraOptions.DEDUCTION_PART = deductionPart;
  }

  shouldShowCollectBy(feeClass) {
    return ['AGENT_DEPOSIT', 'AGENT_WITHDRAWAL', 'AGENT_BALANCE_INQUIRY'].includes(feeClass);
  }

  isAccountEarlyClosureFee() {
    return this.isAccountCustomFee() && this.feeDefinition.applyOn === 'ACCOUNT_EARLY_CLOSURE';
  }

  isAccountCustomFee() {
    return this.productGroup === 'ACCOUNT' && this.feeDefinition.feeClass === 'CUSTOM';
  }

  isAccountTaggedMemoFee() {
    return this.isAccountCustomFee() && ['TAGGED_DEBIT_MEMO', 'TAGGED_CREDIT_MEMO'].includes(this.feeDefinition.applyOn)
  }

  isPartnerOperation() {
    return ['PARTNER_DEPOSIT', 'PARTNER_WITHDRAWAL'].includes(this.feeDefinition.applyOn);
  }

  isExternalTransfer() {
    return ['EXTERNAL_TRANSFER_INCOMING', 'EXTERNAL_TRANSFER_OUTGOING'].includes(this.feeDefinition.applyOn);
  }

  collectionTypeEnabled() {
    return ['PENALTY_MATURITY', 'PENALTY'].includes(this.feeDefinition.feeClass);
  }

  getExternalTransferChannels() {
    return EXTERNAL_TRANSFER_CHANNELS;
  }

  amortizationTypeChanged() {
    if (this.feeDefinition.feeAmortizationType === 'EQUALLY_CYCLIC') {
      this.useFeeBoard = false;
      this.clearBoards();
    }
    this.feeDefinition.amortizedFeeConfig = null;
    this.updateCalculationMethods();
  }

  clearAmortizedFeeConfig() {
    this.feeDefinition.feeAmortizationType = null;
    this.feeDefinition.amortizedFeeConfig = null;
  }

  setOverDraftStrategy() {
    this.feeDefinition.overdraftStrategy = this.isExternalTransfer()
      ? this.externalTransferOverDraftStrategy
      : 'OVERDRAFT';
  }
}

nxModule.component('customFee', {
  templateUrl,
  transclude: true,
  bindings: {
    feeDefinition: '=',
    allFeeDefinitions: '<',
    index: '<',
    branches: '<',
    loanInsuranceAccounts: '<',
    productGroup: '<'
  },
  controller: CustomFee
});
