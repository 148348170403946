import { reportTypeResolver, reportCodeResolver } from "./resolvers";
import angular from "angular-route";

export const reportRoutes: [string, angular.route.IRoute & {label?: string}][] = [
  ['/report/:reportType', {
      template: '<report-list/>',
      label: 'report-list'
    }
  ], ['/report/:reportType/reports/:reportCode', {
    template: '<report-details report-type="$resolve.reportType" report-code="$resolve.reportCode"/>',
    label: 'report-details',
    resolve: {
      ...reportTypeResolver,
      ...reportCodeResolver
    }
  }], ['/custom-report', {
      template: '<custom-report/>',
      label: 'Custom reports'
  }]
];