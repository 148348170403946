import {customerPath, groupCustomerPath} from './customer.routes';
import {
  checkPermissions,
  customerIdResolver,
  customerResolver,
  groupLoanIdResolver,
  loanIdResolver,
  loanResolver,
  loanTypeIdResolver,
  operationIdResolver
} from './resolvers';
import angular from "angular-route";
import {NxRouteService} from "routes/NxRouteService";
import {PermissionContext} from "shared/utils/permission.service";

const groupLoansPath = `${customerPath}/group-loans`;
const loansPath = `${customerPath}/loans`;
const loanPath = `${loansPath}/:loanId`;

const loanPermissionContext = ($route: NxRouteService): PermissionContext => {
  return {
    customerId: Number($route.current.params['customerId']),
    loanId: $route.current.params['loanId'] ? Number($route.current.params['loanId']) : undefined
  };
};

export const loansRoutes: [string, angular.route.IRoute & {label?: string, options?: unknown}][] = [
  [`${loansPath}/create`, {
    template: '<customer-loan-create-list customer-id="$resolve.customerId"/>',
    label: 'New Loan',
    resolve: {
      ...customerIdResolver
    }
  }], [`${loansPath}/consolidate`, {
    template: '<customer-loan-create-list creation-type="\'CONSOLIDATION\'" customer-id="$resolve.customerId"/>',
    label: 'Consolidate Loans',
    resolve: {
      ...customerIdResolver
    }
  }], [`${loansPath}/restructure/:loanId`, {
    template: '<customer-loan-create-list creation-type="\'RESTRUCTURED\'" loan="$resolve.loan" customer-id="$resolve.customerId"/>',
    label: 'Restructure Loan',
    resolve: {
      ...loanResolver,
      ...customerIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loansPath}/reconstruct/:loanId`, {
    template: '<customer-loan-create-list creation-type="\'RECONSTRUCTED\'" loan="$resolve.loan" customer-id="$resolve.customerId"/>',
    label: 'Reconstruct Loan',
    resolve: {
      ...loanResolver,
      ...customerIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loansPath}/create/:loanTypeId`, {
    template: '<customer-loan-create-page customer-id="$resolve.customerId" loan-type-id="$resolve.loanTypeId" header="\'Open new loan\'"/>',
    label: '<null>',
    resolve: {
      ...customerIdResolver,
      ...loanTypeIdResolver
    }
  }], [`${loansPath}/restructure/:loanId/create/:loanTypeId`, {
    template: '<customer-loan-restructure-page loan-type-id="$resolve.loanTypeId"  remake-type="\'RESTRUCTURED\'" loan="$resolve.loan"/>',
    label: 'Restructure Loan',
    resolve: {
      ...loanTypeIdResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loansPath}/consolidate/:loanTypeId`, {
    template: '<customer-loan-create-page customer-id="$resolve.customerId" loan-type-id="$resolve.loanTypeId"  creation-type="\'CONSOLIDATION\'" header="\'Open consolidated loan\'"/>',
    label: 'Consolidate Loans',
    resolve: {
      ...customerIdResolver,
      ...loanTypeIdResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loansPath}/reconstruct/:loanId/create/:loanTypeId`, {
    template: '<customer-loan-restructure-page loan-type-id="$resolve.loanTypeId" remake-type="\'RECONSTRUCTED\'" loan="$resolve.loan"/>',
    label: 'Reconstruct Loan',
    resolve: {
      ...loanTypeIdResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/renew`, {
    template: '<customer-loan-renewal-page loan="$resolve.loan"/>',
    label: 'Renew Loan',
    resolve: {
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/release/cashier-check`, {
    template: '<customer-loan-check-release/>',
    label: 'customer-loan-check-release-label',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${groupLoansPath}/:groupLoanId/release/check`, {
    template: '<customer-group-loan-check-release/>',
    label: 'Release group loan by cashier\'s check'
  }], [`${groupLoansPath}/:groupLoanId/release/cash`, {
    template: `<customer-group-loan-cash-release
                     customer-id="$resolve.customerId"
                     group-loan-id="$resolve.groupLoanId"
                   />`,
    label: 'Release group loan by cash',
    resolve: {
      ...customerIdResolver,
      ...groupLoanIdResolver
    }
    }], [`${loanPath}/release/restructure`, {
    template: `<customer-loan-release
                    method="RESTRUCTURE"
                    customer-id="$resolve.customerId"
                    loan="$resolve.loan"
                 />`,
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    label: 'Loan Release',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/release/consolidation`, {
    template: `<customer-loan-release
                    method="CONSOLIDATION"
                    customer-id="$resolve.customerId"
                    loan="$resolve.loan"
                 />`,
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    label: 'Loan Release',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/release/cash`, {
    template: `<customer-loan-release
                      method="CASH"
                      customer-id="$resolve.customerId"
                      loan="$resolve.loan"
                   />`,
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    label: 'Loan Release',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/release/credit-to-account`, {
    template: `<customer-loan-release
                      method="CREDIT_TO_ACCOUNT"
                      customer-id="$resolve.customerId"
                      loan="$resolve.loan"
                   />`,
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    label: 'Loan Release',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/release/check`, {
    template: `<customer-loan-release
                      method="CHECK"
                      customer-id="$resolve.customerId"
                      loan="$resolve.loan"
                   />`,
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    label: 'Loan Release',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/release/general-ledger`, {
    template: `<customer-loan-release
                      method="GENERAL_LEDGER"
                      customer-id="$resolve.customerId"
                      loan="$resolve.loan"
                   />`,
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    label: 'Loan Release',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${groupLoansPath}/:groupLoanId/disbursment`, {
    template: '<group-loans-disbursment/>',
    label: 'Group loan disbursment'
  }], [`${loanPath}/edit-amortization-without-principal`, {
    template: '<customer-loans-edit-amortization edit-principal="false"/>',
    label: '<null>',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-amortization-with-principal`, {
    template: '<customer-loans-edit-amortization edit-principal="true"/>',
    label: '<null>',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-metadata`, {
    template: '<customer-loans-edit-metadata/>',
    label: '<null>',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-rates`, {
    template: '<customer-loans-edit-rates customer-id="$resolve.customerId" loan="$resolve.loan"/>',
    label: '<null>',
    resolve: {
      ...customerIdResolver,
      ...loanResolver,
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-params`, {
    template: `<customer-loan-edit-params-and-charges
                      customer-id="$resolve.customerId"
                      loan="$resolve.loan"
                   />`,
    label: '<null>',
    resolve: {
      ...customerIdResolver,
      ...loanResolver,
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/recreate-discount-charges`, {
    template: '<recreate-discount-charges-ledger customer-id="$resolve.customerId" loan="$resolve.loan"/>',
    label: 'Recreate discount charges ledger',
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-information`, {
    template: '<customer-loans-edit-information/>',
    label: '<null>',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-documents`, {
    template: '<loan-edit-documents loan="$resolve.loan"/>',
    label: 'Edit Loan Documents',
    resolve: {
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-interest-accrual`, {
    template: '<customer-loans-edit-interest-accrual customer-id="$resolve.customerId" loan="$resolve.loan"/>',
    label: 'Edit accrued interest',
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/recalculate-amortization-schedule`, {
    template: '<recalculate-amortization-schedule loan="$resolve.loan" customer-id="$resolve.customerId"/>',
    label: 'Recalculate amortization schedule',
    resolve: {
      ...loanResolver,
      ...customerIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/edit-co-maker`, {
    template: `<customer-loan-edit-co-makers loan="$resolve.loan"/>`,
    label: '<null>',
    resolve: {
      ...loanResolver,
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/ropa-transfer`, {
    template: '<loan-ropa-transfer customer-id="$resolve.customerId" loan="$resolve.loan">',
    label: 'Transfer to ROPA',
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/cash-payment`, {
    template: '<customer-loans-cash-payment/>',
    label: 'Cash payment',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/transfer-payment`, {
    template: '<customer-loans-transfer-payment customer-id="$resolve.customerId" loan="$resolve.loan"/>',
    label: 'Payment by transfer',
    resolve: {
      ...customerIdResolver,
      ...loanResolver,
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/check-payment`, {
    template: '<customer-loans-check-payment/>',
    label: 'Check payment',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/gl-payment`, {
    template: `<customer-page><customer-loans-gl-payment customer-id="$resolve.customerId" loan="$resolve.loan" /></customer-page>`,
    label: 'Payment by General Ledger',
    resolve: {
      ...customerIdResolver,
      ...loanResolver,
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/revert-payment-to-gl/:operationId`, {
    template: `<customer-page><loans-payment-revert-to-gl customer-id="$resolve.customerId" loan="$resolve.loan" operation-id="$resolve.operationId"/></customer-page>`,
    label: 'Payment revert to General Ledger',
    resolve: {
      ...customerIdResolver,
      ...loanResolver,
      ...operationIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/statement`, {
    template: '<customer-page><customer-loans-statement/></customer-page>',
    label: 'Statement of Account - Per amortization',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/statement-total`, {
    template: '<customer-page><customer-loans-statement-total customer-id="$resolve.customerId" loan-id="$resolve.loanId"/></customer-page>',
    label: 'Statement of Account - Totals',
    resolve: {
      ...customerIdResolver,
      ...loanIdResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/letters`, {
    template: `<customer-page>
                      <loan-letters
                        customer="$resolve.customer"
                        loan="$resolve.loan"
                        loan-letters="$resolve.letters"
                      />
                   </customer-page>`,
    label: 'Letters',
    resolve: {
      ...customerResolver,
      ...loanResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/payment-report`, {
    template: '<customer-page><loan-payment-report/></customer-page>',
    label: 'Loan Payment Report',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/schedule-report`, {
    template: '<customer-page><amortization-schedule-report/></customer-page>',
    label: 'Amortization Schedule Report',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/amortization/:amortizationId/waive`, {
    template: '<customer-amortization-waive/>',
    label: 'Waive',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/waive`, {
    template: '<customer-amortization-waive/>',
    label: 'Waive',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/issue-pdc`, {
    template: '<loan-issue-pdc customer-id="$resolve.customerId" loan="$resolve.loan"/>',
    label: 'Issue PDC',
    resolve: {
      ...customerIdResolver,
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }],
  [loansPath, {
    template: '<customer-loans/>',
    label: 'Loans',
    options: {
      header: 'Batch clear inwards',
      breadcrumbs: 'Batch clearing / Checks'
    }
  }], [loanPath, {
    template: '<customer-loans/>',
    label: '<null>'
  }], [`${loanPath}/pdc/:pdcId`, {
    template: '<loan-edit-pdc pdc-id="$resolve.pdcId" loan="$resolve.loan"/>',
    label: 'Edit PDC',
    resolve: {
      pdcId: /* @ngInject */ ($route: NxRouteService) => Number($route.current.params['pdcId']),
      ...loanResolver
    },
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/assign-group`, {
    template: '<customer-loans-assign-group/>',
    label: 'Assign to group',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/pre-terminate/cash`, {
    template: '<loan-pre-terminate-by-cash/>',
    label: 'Pre-terminate by Cash',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/pre-terminate/transfer`, {
    template: '<loan-pre-terminate-by-transfer/>',
    label: 'Pre-terminate by Transfer',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], [`${loanPath}/pre-terminate/general-ledger`, {
    template: '<loan-pre-terminate-by-gl/>',
    label: 'Pre-terminate by GL',
    resolveRedirectTo: checkPermissions(['CST_EMPLOYEE_LOANS_READ'], loanPermissionContext)
  }], ['/customer/group-loans/create', {
    template: '<group-profile/>',
    label: 'New Group'
  }], [`${groupLoansPath}/create`, {
    template: '<group-loans-create>',
    label: 'New Loan'
  }], [groupLoansPath, {
    template: '<group-loans/>',
    label: 'Group Loans'
  }], [`${groupLoansPath}/:loanId`, {
    template: '<group-loans/>',
    label: '<null>'
  }], [`${groupLoansPath}/:batchId/assign`, {
    template: '<group-loans-assign/>',
    label: '<null>'
  }], [`${customerPath}/group-profile`, {
    template: '<group-profile/>',
    label: 'Group Profile'
  }], [`${groupCustomerPath}/transfer/:customerId`, {
    template: '<transfer-customer-to-another-group/>',
    label: 'Transfer customer'
  }]
];
