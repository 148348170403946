import nxModule from 'nxModule';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery';

import templateUrl from './users-details.template.html';
import {setBreadcrumbs} from 'shared/utils/set-breadcrumbs';
import CredentialsUpdateService from "../../../../../react/management/user/CredentialsUpdateService";
import systemPropertyService from '../../../../../react/system/systemPropertyService';

nxModule.component('usersDetails', {
  templateUrl,
  bindings: {
    branches: '<',
    canExecuteCommand: '<',
    forcedCredentialsUpdate: '<'
  },
  controller: function (userCache, http, $route, $timeout, breadcrumbs, rolesCache, confirmation,
                        $location, $filter, authentication, notification, $routeParams, config,
                        systemDateService, command, popup, agentPhoneNumberService,
                        passwordComplexityService) {
    let that = this;

    that.user = {};
    that.roles = null;
    that.branchId = $route.current.params.branchId;
    that.userId = parseInt($route.current.params.userId);
    that.selectedStatus = 'all';
    const parent = `/admin/organization/users/${that.branchId}`;
    that.permissions = authentication.permissions;
    that.transactionLimitRequired = false;

    that.canCreateUsers = that.canExecuteCommand('CreateUser');
    that.canUpdateUsers = that.canExecuteCommand('UpdateUser');
    that.canUpdateOwnData = that.canExecuteCommand('UpdateUserOwnData');
    that.canUpdateUsersCredentials = that.canExecuteCommand('UpdateUserCredentials');
    that.canDeleteUsers = that.canExecuteCommand('DeleteUser');
    that.editingOwnData = that.userId === authentication.context.id;
    that.credentialsUpdateService = new CredentialsUpdateService();

    that.canChangeStatus = true;
    that.canSuggestNewStatus = true;
    that.showExpirationDateError = false;
    that.canSeeMfa = false;
    that.saveAndCancelButtonAreHidden = false;
    that.isExistingUser = false;
    that.createdByUserName = '';
    that.updatedByUserName = '';
    that.isParentViewForbidden = true;

    that.$onInit = async () => {
      that.branch = _.find(that.branches, {id: parseInt(that.branchId)});
      that.currentDate = moment();
      setBreadcrumbs(breadcrumbs, 'users-list-label', that.branch.name);

      if (!that.canUpdateUsers && !that.canUpdateUsersCredentials && !that.editingOwnData) {
        $location.path('/lookup-customer');
      }

      // User has no business reason to see parent view
      that.isParentViewForbidden = !that.canCreateUsers && !that.canUpdateUsers && !that.canDeleteUsers;

      that.complexityRules = passwordComplexityService.getComplexityRules();
    };

    let subscriptions = [];
    /**
     * Removes technical roles from given list
     */
    const filterRoles = (roles, userRoles) => {
      const userRole = _.head(userRoles);
      if(!userRole) {
        return roles.filter(role =>
          role.agent === false && role.technical === false
        );
      }

      return roles.filter(role =>
        role.agent === userRole.agent &&
          (role.technical === false || role.id === userRole.id)
      );
    };

    that.initExistingUser = async () => {
      // For existing users roles should be composed
      let s = userCache.withParam().toObservable().combineLatest(rolesCache.toObservable(), (users, roles) => {
        let user = _.find(users, d => d.id === that.userId);
        that.roles = filterRoles(roles, user.roles);
        const nonTechnicalRoleIds = that.roles.map(r => r.id);
        if (user.roles && user.roles.length > 0) {
          const role = user.roles.find(r => nonTechnicalRoleIds.includes(r.id));
          user.roleId = role?.id;
        }
        that.user = user;

        if (that.user.expirationDate) {
          that.showExpirationDateError = that.currentDate.isAfter(moment(that.user.expirationDate));
        }

        that.minExpirationDate = that.currentDate.toDate();

        if (!that.user.enabled) {
          // if user is blocked prevent auto-suggesting new status to avoid accidental account manual activation
          that.canSuggestNewStatus = false;
        }

        // For user audit trail
        that.createdByUserName = that.formatNameWithRoles(that.user.createdBy, users);
        that.updatedByUserName = that.formatNameWithRoles(that.user.updatedBy, users);
        that.user.createdAt = moment(that.user.createdAtTimeStamp).format('YYYY-DD-MM h:mm:ss a');
        that.user.updatedAt = moment(that.user.updatedAtTimeStamp).format('YYYY-DD-MM h:mm:ss a');
        that.isExistingUser = true; // show audit trail tab in viewing/editing of user only.
      }).subscribe(users => {
        that.onRoleUpdate();
        setBreadcrumbs(breadcrumbs, 'users-details-label', that.user.effectiveName);
        forceCredentialsUpdatePopup();
      });
      subscriptions.push(s);

      // load user auth details
      const canConfigureMfa = that.canExecuteCommand('ConfigureMfa', authentication.context.roleIds, {userId: that.userId});
      that.canSeeMfa = that.canExecuteCommand('UpdateUserMfaStatus')
        || canConfigureMfa
        || that.editingOwnData;
    };

    that.initNewUser = () => {
      setBreadcrumbs(breadcrumbs, 'users-details-label', 'CREATE');
      that.user = {
        branchId: that.branchId,
        branchIds: [parseInt(that.branchId)],
        enabled: true,
        middleName: '',
        roles: [],
        expirationDate: null,
        activationDate: that.currentDate.toDate()
      };

      that.minExpirationDate = that.currentDate.toDate();

      let rolesCacheSub = rolesCache.toObservable().subscribe(roles => {
        that.roles = filterRoles(roles, this.user.roles);
        that.onRoleUpdate();
      });
      subscriptions.push(rolesCacheSub);
    };

    $timeout(async () => {
      if (that.userId) {
        that.initExistingUser();
      } else {
        that.initNewUser();
      }

      // On init set form as submitted to highlight invalid fields
      that.userForm.$setSubmitted();
      that.passwordForm.$setSubmitted();
      if ($routeParams.showPasswordTab === 'true') {
        that.complexityRules = passwordComplexityService.getUpdatedComplexityRules();
        $("a[data-target='#tab-password']").click();
        notification.show("Information", "You have to change your password");
      }
    });

    that.onRoleUpdate = () => {
      if (that.user.roleId) {
        let role = _.find(that.roles, {id: that.user.roleId});
        that.transactionLimitRequired = role.transactionLimitRequired;
      } else {
        that.transactionLimitRequired = false;
        that.user.transactionLimit = null;
      }
    };

    that.changeActivationDate = () => {
      that.updateUserAccountStatusRestrictions();
    };

    that.changeExpirationDate = (value) => {
      if (value) { //Ng-model with min is doing validation for us
        that.showExpirationDateError = false;
      }

      that.updateUserAccountStatusRestrictions();
    };

    that.updateUserAccountStatusRestrictions = () => {
      if (that.user.activationDate && that.currentDate.isBefore(that.user.activationDate, 'day')) {
        // account will be activated in the future => initially should be disabled
        that.user.enabled = false;

        that.canChangeStatus = false;
        that.minExpirationDate = that.user.activationDate;
      } else {
        that.canChangeStatus = true;

        if (that.showExpirationDateError || !that.canSuggestNewStatus ||
          (that.user.expirationDate && that.currentDate.isAfter(that.user.expirationDate, 'day'))) {
          // account already expired - should not be activated automatically
          // case for editing expired user
          return;
        }

        // account should be activated right after creation
        that.user.enabled = true;
        that.minExpirationDate = that.currentDate;
      }
    };

    that.updateCredentials = async () => {
      // When updating own password or pin, make sure to use 'UpdateUserOwnCredentials' command.
      // This is to ensure that a validation for the requirement of `oldPassword` property is checked.
      const commandName = that.editingOwnData ? 'UpdateUserOwnCredentials' : 'UpdateUserCredentials';

      const input = {
        userId: that.userId,
        password: {
          newPassword: that.user.password,
          oldPassword: that.oldPassword,
          forcePasswordUpdate: that.user.forcePasswordUpdate
        },
        pinInput: {
          pin: that.user.pin,
          forcePinUpdate: that.user.forcePinUpdate
        }
      };

      await command.execute(commandName, input).toPromise();
    };

    that.cancelChanges = () => {
      confirmation('Do you want to cancel? Canceling will discard all changes.', () => $location.path(parent));
    };

    that.configureMfa = async () => {
      $location.path(`${parent}/user/${that.userId}/mfa`);
    };

    that.prepareUserInput = () => {
      return {...that.user,
               roles: that.user.roleId ? [{id: that.user.roleId}] : [],
               expirationDate: $filter('nxDate')(that.user.expirationDate),
               activationDate: $filter('nxDate')(that.user.activationDate)
             };
    };

    that.save = async () => {
      if (!that.userId) {
        const result = await command.execute('CreateUser', that.prepareUserInput()).toPromise();

        if (result) {
          await userCache.withParam().refetch();
          $location.path(parent);
        }
        return;
      }

      let shouldLogOut = that.editingOwnData && (Boolean(that.user.password) || that.forcedCredentialsUpdateOnly);
      if (that.retypedPassword || that.user.pin) {
        await that.updateCredentials();
      }

      await that.updateUser(shouldLogOut).toPromise();
    };

    that.redirect = () => {
      if (!that.isParentViewForbidden) {
        $location.path(parent);
      } else {
        // do not redirect to user list if can't edit their data
        $location.path(`${parent}/user/${that.userId}`);
      }
    };

    that.updateUser = async (shouldLogout) => {
      // If an user is updating his own data use UpdateUserOwnData command.
      const commandName = that.editingOwnData ? 'UpdateUserOwnData' : 'UpdateUser';

      if (!that.canExecuteCommand(commandName)) {
        // no access rights - stopping
        console.debug('No rights to update the profile');

        if(shouldLogout) {
          authentication.logout();
        }

        return;
      }

      const response = await command.execute(commandName, {
        userId: that.userId,
        user: that.prepareUserInput()
      }).toPromise();

      if (response && !response.approvalRequired) {
        await userCache.withParam().refetch();

        try{
          await agentPhoneNumberService.refreshCustomerPhoneNumber(that.branchId, that.userId);
        } catch(err){
          console.error('Clearing cache failed for agent phone number');
        }

        if(shouldLogout) {
          authentication.logout();
        } else {
          that.redirect();
        }
      }
    };

    that.changeBranch = () => {
      $location.path(`${parent}/user/${that.userId}/move-to-branch`);
    };

    that.$onDestroy = () => {
      _(subscriptions).forEach(s => s.unsubscribe());
    };

    const forceCredentialsUpdatePopup = async () => {
      if (that.credentialsUpdateService.isForceCredentialsUpdate(that.permissions)) {

        that.forcedCredentialsUpdateOnly = true;
        let isPasswordExpired = false;

        if (that.user.forcePasswordUpdate) {

          const expirationNrOfDays = systemPropertyService.getProperty('EXPIRE_PASSWORD_AFTER_NR_OF_DAYS');
          const expirationDate = moment(that.user.lastPasswordModificationDate).add(Number(expirationNrOfDays), 'days');
          isPasswordExpired = !that.currentDate.isBefore(expirationDate);

        }

        let msg = null;
        const msgAdjective = isPasswordExpired ? 'expired' : 'temporary';

        if (that.user.forcePasswordUpdate && that.user.forcePinUpdate) {
          msg = `Please update your ${msgAdjective} password and temporary PIN.`;
        } else if (that.user.forcePinUpdate) {
          msg = 'Please update your temporary PIN.';
        } else if (that.user.forcePasswordUpdate) {
          msg = `Please update your ${msgAdjective} password`;
        }

        if(msg){
          popup({
            text: msg
          });
        }
      }
    };

    that.hideCredentialsForm = (credentialsType) => {
      switch (credentialsType) {
        case 'PASSWORD':
          return that.forcedCredentialsUpdate && !that.user.forcePasswordUpdate;
        case 'PIN':
          return that.forcedCredentialsUpdate && !that.user.forcePinUpdate;
        default:
          console.error('Invalid credentialsType');
      }
    }

    that.isCredentialsRequired = (credentialsType) => {
      switch (credentialsType) {
        case 'PASSWORD':
          return that.editingOwnData && that.user.forcePasswordUpdate;
        case 'PIN':
          return that.editingOwnData && that.user.forcePinUpdate;
        default:
          console.error('Invalid credentialsType');
      }
    }

    that.showSaveAndCancelButtons = () => {
      that.saveAndCancelButtonAreHidden = false
    };

    that.hideSaveAndCancelButtons = () => {
      that.saveAndCancelButtonAreHidden = true
    };

    that.formatNameWithRoles = (userId, users) => {
      const user = users.find(u => u.id === userId);
      if(!user) {
        return `<deleted_user_${userId}>`;
      }

      const lastAndFirstName = `${user.lastName} ${user.firstName}`;
      if (user.roles && user.roles.length > 0) {
        const userRoles = `(${_.map(user.roles, 'name').join(', ')})`;
        return lastAndFirstName + ' ' + userRoles;
      }

      return lastAndFirstName;
    };

  }
});
