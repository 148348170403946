import {ApprovalHierarchy} from "../../common/approval-hierarchy/approval-hierarchy.types";
import ParameterizedLocalCache from "shared/utils/parameterized-local-cache";

export interface AccessRule {
  id:                           number;
  predicates:                   Predicates;
  command:                      string;
  commandGroup?:                 CommandGroup;
  roleId:                       number;
  executionType:                AccessRuleExecutionType;
  approve:                      boolean;
  otpRequired:                  boolean;
  displayName?:                 string;
  allowedOnHoliday:             boolean;
}

export interface Predicates {
  LESS_THAN_AMOUNT : Record<string, number>,
  LESS_THAN_OR_EQUAL_AMOUNT: Record<string, number>,
  GREATER_THAN_AMOUNT: Record<string, number>,
  GREATER_THAN_OR_EQUAL_AMOUNT: Record<string, number>,
  BRANCH_ACCESS_MODE: Record<string, BranchAccessMode>,
  USER_ACCESS_MODE: Record<string, UserAccessMode>,
  EQUALS: Record<string, any>,
  IN: Record<string, []>
}

export interface CommandPredicates {
  [command: string]: Record<string, AccessPredicateType>
}

export enum BranchAccessMode {
  InterBranch = "INTER_BRANCH",
  MotherBranch = "MOTHER_BRANCH",
}

export enum UserAccessMode {
  CurrentUserOnly = "CURRENT_USER_ONLY",
}

export enum AccessRuleExecutionType {
  NONE = 'NONE',
  EXECUTE = 'EXECUTE',
  EXECUTE_WITH_APPROVAL = 'EXECUTE_WITH_APPROVAL',
  EXECUTE_WITH_APPROVAL_HIERARCHY = 'EXECUTE_WITH_APPROVAL_HIERARCHY'
}

export enum CommandGroup {
  MISC        = 'MISC',
  PRODUCT     = 'PRODUCT',
  ACCOUNT     = 'ACCOUNT',
  DEPOSIT     = 'DEPOSIT',
  LOAN        = 'LOAN',
  PAWN        = 'PAWN',
  CHECK       = 'CHECK',
  CIF         = 'CIF',
  MANAGEMENT  = 'MANAGEMENT',
  LEDGER      = 'LEDGER',
  AGENT       = 'AGENT',
  MOBILE      = 'MOBILE',
  TECHNICAL   = 'TECHNICAL'
}

export const NON_TECHNICAL_COMMAND_GROUPS: CommandGroup[] = Object.values(CommandGroup)
  .filter(group => ![CommandGroup.TECHNICAL, CommandGroup.PAWN].includes(group));

export interface CommandAccessRuleDefinition {
  command               : string;
  commandGroup          : CommandGroup;
  predicates            : Predicates[];
  displayName?          : string;
  ruleDefinitions       : AccessRuleDefinition[];
}

export interface ExecuteOption {
  label: string;
  value: AccessRuleExecutionType
}

export const NEW_RULE_EXECUTE_OPTIONS: ReadonlyArray<ExecuteOption> = Object.freeze([
  {
    label: 'Cannot execute',
    value: AccessRuleExecutionType.NONE
  },
  {
    label: 'Execute',
    value: AccessRuleExecutionType.EXECUTE
  },
  {
    label: 'Execute with approval',
    value: AccessRuleExecutionType.EXECUTE_WITH_APPROVAL
  }
]);

export const EXISTING_RULE_EXECUTE_OPTIONS: ReadonlyArray<ExecuteOption> = Object.freeze([
  ...NEW_RULE_EXECUTE_OPTIONS,
  {
    label: 'Execute with approval hierarchy',
    value: AccessRuleExecutionType.EXECUTE_WITH_APPROVAL_HIERARCHY
  }
]);

export interface AccessRuleInput {
  accessRules: AccessRule[];
  deleteRuleIds: number[];
}

export interface AccessRuleApprovalHierarchyInput {
  accessRule: AccessRule;
  approvalHierarchy: ApprovalHierarchy[];
}

export interface AccessRuleDefinition {
  inputName   : string;
  predicates  : AccessPredicateType[];
}

export interface AccessPredicateType {
  name          : string;
  type          : string;
}


export type AccessRuleCache = ParameterizedLocalCache<unknown, AccessRule[]>;