import nxModule from 'nxModule';
import _ from 'lodash';
import templateUrl from './custom-fee-edit.template.html';
import {Branch} from "management/BranchTypes";
import {HttpService} from "shared/utils/httpService";
import {BranchService} from "components/service/branch.service";
import {Account, Fee} from "components/service/product.types";
import {FeeDefinition} from "components/service/fees/fee.types";
import {CustomerProfile} from "components/customer/profile/customer-profile.types";

class CustomFeeEdit {

  public branches: Branch[] = [];
  public insuranceAccounts: unknown;
  public fee!: Fee;
  public allFeeDefinitions!: FeeDefinition[];
  public productGroup!: string;
  public onSave!: () => void;
  public onCancel!: () => void;

  constructor(private branchService: BranchService,
              private http: HttpService) {
  }

  async $onInit(): Promise<void> {
    this.branches = await this.branchService.toPromise();

    if (this.productGroup === 'LOAN') {
      // load loan insurance accounts only for loan context
      this.insuranceAccounts = await this.getInsuranceAccounts();
    }
  }

  async getInsuranceAccounts(): Promise<unknown[]> {
    const insuranceAccounts = await this.http.get<(Account & {customer?: string})[]>('/products/accounts?purpose=INSURANCE&status=ACTIVE,INACTIVE,PENDING').toPromise();
    if (!_.isEmpty(insuranceAccounts)) {
      const customerIds = insuranceAccounts.map(a => a.customerId);
      const customers = await this.http.get<CustomerProfile[]>(`/customers/all?ids=${customerIds}`).toPromise();
      insuranceAccounts.forEach(acc => acc.customer = customers.find(c => c.id === acc.customerId)!.effectiveName);
    }
    return insuranceAccounts;
  }

  save(): void {
    if (this.onSave) {
      this.onSave();
    }
  }

  cancel(): void {
    if (this.onCancel) {
      this.onCancel();
    }
  }
}

nxModule.component('customFeeEdit', {
  templateUrl,
  bindings: {
    fee: '=',
    allFeeDefinitions: '=',
    productGroup: '<',
    onSave: '&',
    onCancel: '&'
  },
  controller: CustomFeeEdit
});
