import FeeDefinitionCache from 'components/service/fee-definition.cache';
import {FeeDefinition} from 'components/service/fees/fee.types';
import {LoanType} from 'components/service/loan-type.types';
import nxModule from 'nxModule';
import angular, {IFormController, ILocationService} from 'angular';
import {CreateLoanInput, LoanCreationType} from 'components/service/create-loan-input.types';
import {HttpService} from 'shared/utils/httpService';
import {RecursivePartial} from 'shared/utils/RecursivePartial';

import './customer-loan-create-page.style.less';
import templateUrl from './customer-loan-create-page.template.html';
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import {Confirmation} from "shared/common/confirmation.types";
import Authentication from "shared/utils/authentication";

class CustomerLoanCreatePage {
  // bindings
  private customerId!: number;
  private loanTypeId!: number;
  private creationType?: LoanCreationType;
  protected header!: string;
  private penaltyDefinition!: FeeDefinition;

  private loan: RecursivePartial<CreateLoanInput> = {};

  loanForm!: IFormController;

  constructor(private $location: ILocationService,
              private command: CommandService,
              private customerCache: CustomerCache,
              private feeDefinitionsCache: FeeDefinitionCache,
              private authentication: Authentication,
              private http: HttpService,
              private confirmation: Confirmation) {
  }

  async $onInit(): Promise<void> {
    this.loan = {
      customerId: this.customerId,
      loanTypeId: this.loanTypeId,
      loanInformation: {},
      loanDocuments: [],
      automaticTransferAgreement: {
        transferStrategy: 'NONE'
      },
      creationType: this.creationType
    };
    const [feeDefinitions, loanType] = await Promise.all([
      this.feeDefinitionsCache.toPromise(),
      this.http.get<LoanType>(`/products/loans/types/${this.loanTypeId}`).toPromise()
    ]);
    const penalty = feeDefinitions.find(f => f.feeClass === 'PENALTY' && loanType.productDefinition.id === f.productDefinitionId);

    if(penalty) {
      this.penaltyDefinition = penalty;
    }
  }

  redirectBack() : void {
    if(this.creationType === 'CONSOLIDATION') {
      this.$location.path(`/customer/${ this.loan.customerId }/loans/consolidate`);
    } else {
      this.$location.path(`/customer/${ this.loan.customerId }/loans/create`);
    }
  }

  async postLoan(clonedLoan: RecursivePartial<CreateLoanInput>): Promise<void> {
    const customerId = this.customerId;

    if(this.penaltyDefinition.extraOptions?.LOAN_BOARD) {
      clonedLoan.penalty = undefined;
    }

    const { output } = await this.command.execute<unknown, {id: number}>(this.getCreateCommand(), clonedLoan).toPromise();
    if (!output) return;

    const loanId = output.id;
    this.customerCache.loans(customerId).refetch();
    this.customerCache.customerProductFees(customerId).refetch();
    this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(customerId).refetch() : Promise.resolve();
    this.customerCache.depositAccounts(customerId).refetch();
    this.$location.path(`/customer/${ customerId }/loans/${ loanId }`);
  }

  async open() : Promise<void> {
    const proceed = await this.confirmation('Do you want to open the loan?');
    if (!proceed) return;

    const clonedLoan = angular.copy(this.loan);
    clonedLoan.feeOverride = clonedLoan.feeOverride || {};

    this.postLoan(clonedLoan);
  }

  private getCreateCommand(): string {
    switch (this.creationType) {
      case 'CONSOLIDATION':
        return 'CreateConsolidatedLoan'
      default:
        return 'CreateLoan'
    }
  }
}

nxModule.component('customerLoanCreatePage', {
  templateUrl,
  bindings: {
    customerId: '<',
    loanTypeId: '<',
    creationType: '<',
    header: '<'
  },
  controller: CustomerLoanCreatePage
});
