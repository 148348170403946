import axios from "axios";
import JsPDF from "jspdf";
import {sortBy, sortedUniqBy} from "lodash/fp";
import {Print, PrintPage} from "print/PrintType";
import notificationService from "tools/notificationService";
import {Passbook} from "deposit/DepositType";
import Handlebars from 'handlebars';
import initializeHandlebars from "initializeHandlebars";
import {uniq} from "lodash";

const DEFAULT_FONT_SIZE = 8;

const NX_NO_PRINT_CSS_CLASS = `<style>
  @media print {
    .nx-no-print {
      display: none !important;
    }
  }
</style>`;

export const PAGE_BREAK = `<div style='page-break-after: always;'></div>
<div class='nx-no-print' style='text-align: center; color: gray'>
  <hr style="border-color: white">
    Page break
  <hr style="border-color: white">
</div>`;

export interface PrintHistoryIdsInput {
  printHistoryEntryIds: number[]
}

export interface GeneratePrintConfig {
  // base64 backgrounds. one element per page
  backgrounds: (string | null)[]
}

class PrintService {

  public async getPrint({printCode, printParameters}: {printCode: string, printParameters: Record<string, unknown>}): Promise<Print> {
    const {data: print} = await axios.get<Print>(`/print/search`, {
      params: {
        printCode,
        ...printParameters
      }
    });

    return print;
  }

  async generatePrint(prints: Print[], config: GeneratePrintConfig = {backgrounds: []}): Promise<string> {
    if(uniq(prints.map(print => print.type)).length > 1) {
      throw new Error('Unable to generate prints with different print type');
    }

    const isTemplateType = prints[0].type === 'TEMPLATE';

    if(isTemplateType) {
      return this.generateHtml(prints);
    } else {
      return await this.generatePdf(prints, config);
    }
  }
  /**
   * Returns url to pdf
   */
  private async generatePdf(prints: Print[], config: GeneratePrintConfig): Promise<string> {
    const longEdge = prints.map(print => print.longEdge).reduce((a, b) => Math.max(a, b));
    const shortEdge = prints.map(print => print.shortEdge).reduce((a, b) => Math.max(a, b));
    const pdfOrientation = prints[0].orientation === 'PORTRAIT' ? 'portrait' : 'landscape';
    const pdf = new JsPDF(pdfOrientation, 'mm', [longEdge, shortEdge]);

    pdf.viewerPreferences({
      'PrintScaling': 'None'
    });

    pdf.setProperties({
      creator: 'Nextbank',
    });

    let first = true;
    let pageIndex = 0;
    for(let ctr = 0; ctr < prints.length; ctr++) {
      const print = prints[ctr];

      for (const page of print.pages) {
        if (!first) {
          pdf.addPage();
          pageIndex++;
        }

        first = false;

        const hasBackground = config.backgrounds.length > 0;
        if (hasBackground) {
          const background = config.backgrounds[Math.min(pageIndex, config.backgrounds.length - 1)];
          if(background) {
            try {
              if (print.orientation === 'PORTRAIT') {
                pdf.addImage(background, 'JPEG', 0, 0, print.shortEdge, print.longEdge);
              } else {
                pdf.addImage(background, 'JPEG', 0, 0, print.longEdge, print.shortEdge);
              }
            } catch (e) {
              console.log('Cannot draw background, invalid image source. Error: ' + e);
            }
          }
        }

        for (const line of page.lines) {
          if (!line.active) {
            continue;
          }

          if (line.text == null || !line.x || !line.y) {
            console.warn('Incomplete print line', line);
            continue;
          }

          pdf.setFontSize(line.fontSize ?? DEFAULT_FONT_SIZE);

          if (line.maxLength) {
            const multiLine = pdf.splitTextToSize(line.text, line.maxLength);
            pdf.text(multiLine, line.x, line.y);
          } else {
            pdf.text(line.text, line.x, line.y);
          }
        }
      }
    }
    return pdf.output('bloburl') + "#statusbar=0&toolbar=0&navpanes=0&scrollbar=0";
  }

  /**
   * Returns html markup
   */
  private generateHtml(prints: Print[]): string {
    initializeHandlebars(Handlebars);
    const htmls = [];
    for(let ctr = 0; ctr < prints.length; ctr++) {
      const print = prints[ctr];
      if(print.pages.length === 0) {
        console.warn("Empty print", print);
      }

      try {
        const compiledTemplate = Handlebars.compile<Record<string, unknown>>(print.template ?? '');

        const html = print.pages
          .map(page => this.renderPage(compiledTemplate, page))
          .join(PAGE_BREAK);

        // Rendering HTML in the <iframe> does skip all the page breaks which might result in page's content touching
        // each other. Instead preview mode (only) does render fake page breaks to separate prints.
        htmls.push(NX_NO_PRINT_CSS_CLASS + html);
      } catch (e) {
        console.error('Error rendering template', e);
        notificationService({
          text: `Error: ${e.message}`
        });
      }
    }

    const htmlText = htmls.join(PAGE_BREAK);
    return URL.createObjectURL(new Blob([htmlText], {type: 'text/html'}));
  }

  private renderPage(compiledTemplate: HandlebarsTemplateDelegate<Record<string, unknown>>, page: PrintPage): string {
    const context: Record<string, unknown> = {};
    const nonLabelLines = page.lines.filter(line => line.type !== 'LABEL');
    const linesById = sortBy(line => line.id, nonLabelLines);
    const uniqueLines = sortedUniqBy(line => line.field, linesById);
    for (const line of uniqueLines) {
      if (!line.text || !line.x || !line.y) {
        console.warn('Incomplete print line', line);
        continue;
      }

      if (line.type === 'LABEL') {
        // label in html can be achieved by writing text as part of html
        continue;
      } else {
        // multi-variable
        try {
          context[line.field] = JSON.parse(line.text);
        } catch (e) {
          if (line.type === 'MULTI_VARIABLE') {
            console.error('Error reading field value from json', line.field, e);

            notificationService({
              text: `Error reading field ${line.field}`
            });
          } else {
            // some variables can be a JSON. Multi Variable needs to always have a json arra
            context[line.field] = line.text;
          }
        }
      }
    }

    // we should escape this html to prevent xss,
    // but that removes styles from the document,
    // therefore we will loose formatting, so let's
    // don't do that
    return compiledTemplate(context);
  }

  public async handlePassbook(printCode: string, printInput?: unknown): Promise<void> {
    if (!['DEPOSIT_ACCOUNT_PASSBOOK', 'TERM_DEPOSIT_PASSBOOK'].includes(printCode)) {
      return;
    }

    const productId: string = (printInput as Record<string, unknown>)?.productId as string;
    const operationIds: number[] = (printInput  as Record<string, unknown>)?.operationIds as number[];

    const {data: passbook} = await axios.get<Passbook>(`/products/${productId}/passbook`);
    const req = operationIds.map((id, printedOperationIds) => ({
      operationId: id,
      line: passbook.lastUsedLine + printedOperationIds + 1
    }));

    await axios.put(`/products/${productId}/passbook`, req);
  }

  registerPrintEvent(idsInput: PrintHistoryIdsInput): Promise<void> {
    return axios.put('/print/history', {
      ...idsInput
    });
  }
}


export default PrintService;
