import nxModule from 'nxModule';
import $ from "jquery";
import {NgTableParams} from 'ng-table';

const templateUrl = require('./product-holds.template.html');
nxModule.component('productHolds', {
  templateUrl: templateUrl,
  bindings: {
    product: '<'
  },
  controller: function ($filter, $route, http, confirmationTemplate, command, customerCache) {

    const that = this;
    that.holdsTable = undefined;
    that.customerId = $route.current.params['customerId'];

    const convertFilterToQueryString = (params) => {
      return $.param({
        pageNo: params.page() - 1,
        pageSize: params.count(),
        productId: that.product.id
      });
    };

    that.resetHolds = () => {
      if (that.product && that.product.id) {
        that.holdsTable = new NgTableParams({
          count: 20,
        }, {
          counts: [],
          paginationMaxBlocks: 5,
          paginationMinBlocks: 5,
          getData: async params => {
            const queryParams = convertFilterToQueryString(params);
            const items = await http.get(`/products/holds?${queryParams}`, {nxLoaderText: 'Loading holds'}).toPromise();
            params.total(items.totalCount);
            return items.result;
          }
        });
      }
    };

    that.onHoldClick = (hold, $event) => {
      $event.stopPropagation();
      that.selectedHoldId = hold.id;
    };

    that.releaseHold = (hold) => {
      const releaseHoldCommand = hold.type === 'MANUAL' ? 'ReleaseManualHold' : 'ReleaseLoanHold';
      confirmationTemplate({
        question: `Do you want to release hold of ${$filter('nxCurrency')(hold.amount)}?`,
        details: [
          {label: 'Type', description: $filter('prettyEnum')(hold.type)},
          {label: 'Created on', description: $filter('prettyDate')(hold.createdOn)},
          {label: 'Creation remarks', description: hold.creationRemarks}
        ],
        warning: 'This action cannot be reverted',
        yesCallback: async () => {
          await command.execute(releaseHoldCommand, {holdId: hold.id}).toPromise();
          that.resetHolds();
          customerCache.depositAccounts(that.customerId).refetch();
          customerCache.termDeposits(that.customerId).refetch();
        }
      });
    };

    that.$onInit = () => {
      that.resetHolds();
    };

    that.$onChanges = (changes) => {
      if (changes.hasOwnProperty('product')) that.resetHolds();
    };
  }
});
