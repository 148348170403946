import {FeeValue, ProductAndFeeDefintion} from 'components/service/fees/fee.types';
import nxModule from 'nxModule';
import {HttpService} from 'shared/utils/httpService';
import templateUrl from './customer-loans-edit-rates.template.html';
import {Loan} from "../../../service/loan.types";
import {ILocationService} from "angular";
import {allPercentageChargeTypes, pastDueInterestChargeTypes} from 'constants/loan';
import {Charge, ProductFeeInput} from '../../../service/loan-type.types';
import _ from "lodash";
import {NxIFilterService} from "components/technical/angular-filters";
import {Confirmation} from "shared/common/confirmation.types";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";

class EditLoanRates {
  private customerId!: number;
  private loan!: Loan;
  private penaltyDefinition?: ProductAndFeeDefintion;
  private penalty!: ProductFeeInput;

  private newPastDueInterestCharge?: Charge;
  private newPastDueMaturityInterestCharge?: Charge;
  private newPenalty?: ProductFeeInput;
  private newPenaltyMaturity?: Charge;
  private pastDueInterestChargeTypes?: unknown;
  private penaltyChargeTypes?: unknown;

  constructor(
    private readonly $location: ILocationService,
    private readonly $filter: NxIFilterService,
    private readonly confirmation: Confirmation,
    private readonly command: CommandService,
    private readonly customerCache: CustomerCache,
    private readonly http: HttpService
  ) {
  }

  async $onInit(): Promise<void> {
    this.pastDueInterestChargeTypes = pastDueInterestChargeTypes.map(value => ({
      label: this.$filter('prettyEnum')(value),
      value,
    }));
    this.penaltyChargeTypes = [...allPercentageChargeTypes, 'FIXED_AMOUNT'].map(type => ({
      label: this.$filter('prettyEnum')(type),
      value: type
    }));

    const [currentPenalty, fees] = await Promise.all([
      this.http.get<FeeValue>(`/products/loans/${this.loan.id}/product-fee-definition/evaluate?feeClass=PENALTY`).toPromise(),
      this.http.get<ProductAndFeeDefintion[]>(`/products/product-fee-definitions/${this.loan.id}`).toPromise()
    ]);
    this.penaltyDefinition = fees.find(f => f.feeClass === 'PENALTY');
    this.penalty = this.getCharge(currentPenalty);
    this.newPastDueInterestCharge = _.cloneDeep(this.loan.pastDueInterestCharge);
    this.newPastDueMaturityInterestCharge = _.cloneDeep(this.loan.pastDueMaturityInterestCharge);
    this.newPenalty = _.cloneDeep(this.penalty);
    this.newPenaltyMaturity = _.cloneDeep(this.loan.penaltyMaturity);
  }

  goBack(): void {
    this.$location.path(`/customer/${this.customerId}/loans/${this.loan.id}`);
  }

  private getCharge(fee: FeeValue): ProductFeeInput {
    return {
      rate: fee.calculationMethod === 'FIXED_AMOUNT' ? fee.fixedAmount : fee.percentageAmount,
      type: fee.calculationMethod
    } as ProductFeeInput
  }

  async save(): Promise<void> {
    const confirmed = await this.confirmation('Apply loan rate adjustment?');
    if (confirmed) {
      const penaltyInput = this.penaltyDefinition?.extraOptions?.LOAN_BOARD ? null : this.newPenalty;

      await this.command.execute("EditLoanRates", {
        productId: this.loan.id,
        pastDueInterestCharge: this.newPastDueInterestCharge,
        pastDueMaturityInterestCharge: this.newPastDueMaturityInterestCharge,
        penalty: penaltyInput,
        penaltyMaturity: this.newPenaltyMaturity,
      }).toPromise();

      this.customerCache.loans(this.customerId).refetch();
      this.goBack();
    }
  }
}

nxModule.component('customerLoansEditRates', {
  templateUrl,
  controller: EditLoanRates,
  bindings: {
    customerId: '<',
    loan: '<'
  }
});
