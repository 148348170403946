import nxModule from 'nxModule';

import BigNumber from 'bignumber.js';
import {sum} from "../../../../shared/common/MathUtils";
import _ from "lodash";

const templateUrl = require('./loan-pre-terminate.template.html');
nxModule.component('loanPreTerminate', {
  templateUrl: templateUrl,
  bindings: {
    maxValues: '<',
    priorityAmortizationIds: '<',
    formName: '=',
    paymentRequest: '=',
    waiveRequest: '=',
    officialReceipt: '=',
    collectOfficialReceipt: '<',
    waiveOnly: '<',
    loan: '<'
  },
  controller: function ($scope, $route, loanPreTerminateService, loanService, loanProductsCache) {
    let that = this;
    that.loanId = $route.current.params['loanId'];
    that.amortization = null;
    that.amortizationIds = null;
    that.customFeesTotalBalance = new BigNumber(0);
    that.isAsEarned = false;
    that.asEarnedInterest = new BigNumber(0);
    that.asEarnedAutomaticWaiveAmount = new BigNumber(0);

    that.$onInit = async () => {
      that.maxValues.pastDueInterestWithMaturity = bn(that.maxValues.pastDueInterest).plus(that.maxValues.pastDueMaturityInterest).dp(2).toNumber();
      that.maxValues.penaltyWithMaturity = bn(that.maxValues.penalty).plus(that.maxValues.penaltyMaturity).dp(2).toNumber();

      await fetchCustomFees();
      that.updateTotalOutstandingBalance();
      that.updateCustomFeesParams();
      await that.setUpAsEarnedInterest();
    };

    that.setUpAsEarnedInterest = async() => {
      if (that.waiveOnly || !that.loan || that.loan.uidLedger || that.loan.asEarnedInterestCalculation || that.loan.status !== 'ACTIVE') {
        return;
      }
      const loanTypes = await loanProductsCache.toPromise();
      const loanProduct = _.find(loanTypes, (d) => d.id === that.loan.typeId);
      if (loanProduct.asEarnedPreterminationInterestCalculation) {
        that.isAsEarned = true;
        that.asEarnedInterest = await loanPreTerminateService.getAsEarnedInterest(that.loan.id);
        that.asEarnedAutomaticWaiveAmount = that.maxValues.interest - that.asEarnedInterest;
        that.waived.interest = that.asEarnedAutomaticWaiveAmount;
      }
    }

    if (!that.waiveRequest.total) {
      that.waiveRequest.total = {
        totalWaive: 0,
        totalOutstandingBalance: 0,
        totalFinalBalanceToPay: 0
      };
    }

    that.finalValues = {
      principal: 0,
      interest: 0,
      cbu: 0,
      pf: 0,
      tp: 0,
      pastDueInterestWithMaturity: 0,
      penaltyWithMaturity: 0
    };

    that.waived = {
      principal: 0,
      interest: 0,
      cbu: 0,
      pf: 0,
      tp: 0,
      pastDueInterestWithMaturity: 0,
      penaltyWithMaturity: 0,
      custom: {
        fees: [],
        totalAmount: 0
      }
    };

    const bn = (number) => {
      return number ? new BigNumber(number) : new BigNumber(0)
    };

    that.buildWaiveParts = () => {
      if(!that.maxValues) {
        return;
      }

      let waiveRequests = [];
      if (that.finalValues.interest < that.maxValues.interest) {
        waiveRequests.push({
          waiveType: 'INTEREST',
          waiveAmount: that.waived.interest,
        });
      }

      if (that.finalValues.penaltyWithMaturity < that.maxValues.penaltyWithMaturity) {
        waiveRequests.push({
          waiveType: 'PENALTY',
          waiveAmount: that.waived.penaltyWithMaturity,
        });
      }

      if (that.finalValues.pastDueInterestWithMaturity < that.maxValues.pastDueInterestWithMaturity) {
        waiveRequests.push({
          waiveType: 'PAST_DUE_INTEREST',
          waiveAmount: that.waived.pastDueInterestWithMaturity
        });
      }

      if (that.finalValues.cbu < that.maxValues.cbuCharge) {
        waiveRequests.push({
          waiveType: 'CBU',
          waiveAmount: that.waived.cbu
        });
      }

      if (that.finalValues.pf < that.maxValues.pfCharge) {
        waiveRequests.push({
          waiveType: 'PF',
          waiveAmount: that.waived.pf
        });
      }

      if (that.finalValues.tp < that.maxValues.tpCharge) {
        waiveRequests.push({
          waiveType: 'TP',
          waiveAmount: that.waived.tp
        });
      }

      if (that.customFees) {
        that.customFees.forEach(fee => {
          if (bn(fee.finalBalance).isLessThanOrEqualTo(bn(fee.balance))) {
            waiveRequests.push({
              waiveType: 'CUSTOM_FEE',
              waiveAmount: fee.waiveAmount,
              feeDefinitionId: fee.feeDefinitionId
            });
          }
        });
      }


      return waiveRequests;
    };

    that.updateWaiveRequest = () => {
      that.waiveRequest = {
        total: that.waiveRequest.total,
        productId: that.loanId,
        waiveParts: that.buildWaiveParts(),
        priorityAmortizationIds: that.priorityAmortizationIds
      };
    };

    that.updatePaymentRequest = () => {
      const fv = that.finalValues;

      that.paymentRequest = {
        productId: that.loanId,
        amount: that.waiveRequest.total.totalFinalBalanceToPay,

        principalAmount: fv.principal !== 0 ? fv.principal : undefined,
        interestAmount: fv.interest !== 0 ? fv.interest : undefined,
        cbuChargeAmount: fv.cbu !== 0 ? fv.cbu : undefined,
        pfChargeAmount: fv.pf !== 0 ? fv.pf : undefined,
        tpChargeAmount: fv.tp !== 0 ? fv.tp : undefined,
        pastDueInterestAmount: fv.pastDueInterestWithMaturity !== 0 ? fv.pastDueInterestWithMaturity : undefined,
        penaltyAmount: fv.penaltyWithMaturity !== 0 ? fv.penaltyWithMaturity : undefined,
        commandPurpose: 'LOAN_WAVE'
      };
    };

    that.updateTotalWaive = () => {
      that.waiveRequest.total.totalWaive = parseFloat(bn(that.waived.interest)
        .plus(bn(that.waived.cbu))
        .plus(bn(that.waived.pf))
        .plus(bn(that.waived.tp))
        .plus(bn(that.waived.pastDueInterestWithMaturity))
        .plus(bn(that.waived.penaltyWithMaturity))
        .plus(that.calculateTotalWaivedFees())
        .toFixed(2));
    };

    that.updateTotalFinalBalance = () => {
      that.waiveRequest.total.totalFinalBalanceToPay = parseFloat(bn(that.maxValues.principal)
        .plus(bn(that.finalValues.interest))
        .plus(bn(that.finalValues.cbu))
        .plus(bn(that.finalValues.pf))
        .plus(bn(that.finalValues.tp))
        .plus(bn(that.finalValues.pastDueInterestWithMaturity))
        .plus(bn(that.finalValues.penaltyWithMaturity))
        .plus(bn(that.customFeesTotalFinalBalance))
        .toFixed(2));
    };

    that.updateFinalBalances = () => {
      if (that.maxValues) {
        that.finalValues.interest = parseFloat(bn(that.maxValues.interest).minus(bn(that.waived.interest)).toFixed(2));
        that.finalValues.cbu = parseFloat(bn(that.maxValues.cbuCharge).minus(bn(that.waived.cbu)).toFixed(2));
        that.finalValues.pf = parseFloat(bn(that.maxValues.pfCharge).minus(bn(that.waived.pf)).toFixed(2));
        that.finalValues.tp = parseFloat(bn(that.maxValues.tpCharge).minus(bn(that.waived.tp)).toFixed(2));
        that.finalValues.pastDueInterestWithMaturity = parseFloat(bn(that.maxValues.pastDueInterestWithMaturity).minus(bn(that.waived.pastDueInterestWithMaturity)).toFixed(2));
        that.finalValues.penaltyWithMaturity = parseFloat(bn(that.maxValues.penaltyWithMaturity).minus(bn(that.waived.penaltyWithMaturity)).toFixed(2));
        that.updateTotalFinalBalance();
      }
    };

    that.updateCustomFeeBalance = () => {
      if (that.customFees) {
        that.customFees.forEach(fee => {
          fee.finalBalance = parseFloat(bn(fee.balance).minus(bn(fee.waiveAmount))).toFixed(2);
        });

        that.customFeesTotalFinalBalance = sum(that.customFees.map(fee => fee.finalBalance));
      }
    };

    that.updateTotalOutstandingBalance = () => {
      that.waiveRequest.total.totalOutstandingBalance = parseFloat(bn(that.maxValues.principal)
        .plus(bn(that.maxValues.interest))
        .plus(bn(that.maxValues.cbuCharge))
        .plus(bn(that.maxValues.pfCharge))
        .plus(bn(that.maxValues.tpCharge))
        .plus(bn(that.maxValues.pastDueInterestWithMaturity))
        .plus(bn(that.maxValues.penaltyWithMaturity))
        .plus(bn(that.customFeesTotalBalance))
        .toFixed(2));
    };

    that.calculateTotalWaivedFees = () => {
      return sum(_.map(that.customFees, fee => fee.waiveAmount));
    };

    that.updateCustomFeesParams = () => {
      that.updateCustomFeeBalance();
      that.updateFinalBalances();
      that.updateTotalWaive();
      that.updateWaiveRequest();
      that.updatePaymentRequest();
    };

    const fetchCustomFees = async () => {
      const [persistedFees, terminationFees] = await Promise.all([
        loanService.getLoanFees(that.loanId),
        loanPreTerminateService.getCustomPreterminationFees(that.loanId)
      ]);

      // by default, un-applied non-termination fees are included for waiving
      // Fees applied on LOAN_PAYMENT will be added later - there is a need to combine some of them before adding to final list
      that.customFees = _.filter(persistedFees, fee => !['LOAN_PRETERMINATION', 'LOAN_RELEASE', 'LOAN_PAYMENT'].includes(fee.applyOn) && !fee.appliedDate);

      // Each amortization might have its own instance of fee - we have to combine them into one 'virtual' fee for each present fee definition
      _.chain(persistedFees)
        .filter(fee => fee.applyOn === 'LOAN_PAYMENT')
        .groupBy(fee => fee.feeDefinitionId)
        .forEach(fees => {
          that.customFees.push({
            feeName: fees[0].feeName,
            feeDefinitionId: fees[0].feeDefinitionId,
            balance: sum(fees.map(f => f.balance)).toNumber()
          });
        })
        .value();

      // handle case for waiving a single amortization
      if (that.waiveOnly && that.loan && that.priorityAmortizationIds.length === 1) {
        const amortizedFees = await loanService.getAmortizedFees(that.loanId);
        const amortizationId = Number(that.priorityAmortizationIds[0]);
        const amortizationToWaive = that.loan.amortizationSchedule.list.find(amortization => amortization.id === amortizationId);
        if (_.size(amortizedFees) > 0) {
          that.customFees.filter(fee => fee.applyOn === 'LOAN_AMORTIZATION').forEach(fee => {
            const amortizedFee = amortizedFees.find(af => af.feeId === fee.id && af.amortizationLineNumber === amortizationToWaive.lineNumber);
            fee.balance = amortizedFee.balance;
          });
        }
      }

      if (!that.waiveOnly) {
        that.customFees.push(...terminationFees);
      }

      that.customFees.forEach(fee => {
        fee.waiveAmount = 0;
        fee.finalBalance = fee.balance;
      });

      const totalBalance = sum(that.customFees.map(fee => fee.balance));
      that.customFeesTotalBalance = that.customFeesTotalBalance.plus(totalBalance);
    };

    that.$onChanges = change => {
      if (change.maxValues && change.maxValues.currentValue) {
        that.maxValues.pastDueInterestWithMaturity = bn(that.maxValues.pastDueInterest).plus(that.maxValues.pastDueMaturityInterest).dp(2).toNumber();
        that.maxValues.penaltyWithMaturity = bn(that.maxValues.penalty).plus(that.maxValues.penaltyMaturity).dp(2).toNumber();

        that.finalValues.principal = that.maxValues.principal;
        that.finalValues.interest = that.maxValues.interest;
        that.finalValues.cbu = that.maxValues.cbuCharge;
        that.finalValues.pf = that.maxValues.pfCharge;
        that.finalValues.tp = that.maxValues.tpCharge;
        that.finalValues.pastDueInterestWithMaturity = that.maxValues.pastDueInterestWithMaturity;
        that.finalValues.penaltyWithMaturity = that.maxValues.penaltyWithMaturity;
        that.updateTotalFinalBalance();

        that.updateTotalOutstandingBalance();
      }

      that.updatePaymentRequest();
    };

    $scope.$watchGroup(
      ['$ctrl.waived.interest',
        '$ctrl.waived.cbu',
        '$ctrl.waived.pf',
        '$ctrl.waived.tp',
        '$ctrl.waived.pastDueInterestWithMaturity',
        '$ctrl.waived.penaltyWithMaturity'],
      () => {
        that.updateCustomFeesParams();
      });
  }
});
