import _ from 'lodash';
import nxModule from 'nxModule';
import {NxRouteService} from 'routes/NxRouteService';
import {LoanPDCDetails, LoanPDCStatus} from '../../../../service/loan-pdc.types';
import {Amortization, Loan} from '../../../../service/loan.types';
import templateUrl from './loan-pdc-tab.template.html';
import './details/loan-pdc-tab-details.component';
import {getStatusStyle} from '../loan-pdc-status-styling';
import {IController} from 'angular';
import {HttpService} from "shared/utils/httpService";
import {OfficialReceipt} from "components/dashboard/miscellaneous-transactions/official-receipt/official-receipt.types";
import systemPropertyService from "system/systemPropertyService";
import Authentication from "shared/utils/authentication";
import {CustomerCache} from "components/service/customer.cache.types";
import {CommandService} from "shared/utils/command/command.types";
import Popup from "shared/common/popup";
import {ModalApi} from "components/technical/modal/modal.component";

export interface LoanPdcDetailsWithAmortization extends LoanPDCDetails {
  amortization: Amortization | undefined;
}

interface LoanPaymentOutput {
  valid: boolean;
  operationId?: number;
}

interface LoanPaymentOutputWithErrors extends LoanPaymentOutput {
  errors: string[];
}

class LoanPdcTab implements IController {
  private loan!: Loan;
  private pdcList!: LoanPdcDetailsWithAmortization[];
  private selectedPdcId: number | undefined | null;
  private autoReceiptEnabled: boolean = systemPropertyService.getProperty('AUTOMATED_OFFICIAL_RECEIPTS_ENABLED') === 'TRUE';
  private officialReceipt: OfficialReceipt | null = null;
  private displayEmptyReceiptMessage: boolean = false;

  // due to CSS stacking context and z-index problems
  // modals can't be placed in loan-pdc-tab-details
  cancelModal?: ModalApi;
  cancelRemarks: string | undefined;

  postModal?: ModalApi;
  postRequest: { officialReceipt?: string; temporaryReceipt?: string } = {};

  constructor(
    private readonly http: HttpService,
    private readonly command: CommandService,
    private readonly customerCache: CustomerCache,
    private readonly popup: Popup,
    private authentication: Authentication,
    private readonly $route: NxRouteService
  ) {
  }

  displayPdcDetails(pdc: LoanPdcDetailsWithAmortization, $event: Event): void {
    // Without this line inline-panel doesn't work
    $event.stopPropagation();
    this.selectedPdcId = (pdc && pdc.id) ? pdc.id : null;
  }

  hidePdcDetails(): void {
    this.selectedPdcId = null;
  }

  async $onInit(): Promise<void> {
    if (this.loan && this.loan.id) {
      const pdcList = await this.customerCache.loanPDCs(this.loan.customerId, this.loan.id).toPromise();
      this.updatePdcList(pdcList);
    }

    if(this.autoReceiptEnabled) {
      this.officialReceipt = <OfficialReceipt> await this.http.get(`/official-receipt/next-available?userId=${this.authentication.context.id}`)
        .toPromise()
        .catch(response => {
          if(response?.errorCode === 'NO_AVAILABLE_OFFICIAL_RECEIPT') {
            this.displayEmptyReceiptMessage = true;
          } else {
            throw response;
          }
        });

      if(this.officialReceipt) {
        this.postRequest.officialReceipt = this.officialReceipt.receiptNumber;
      }
    }
  }

  updatePdcList(pdcList: LoanPDCDetails[]): void {
    this.pdcList = pdcList.map<LoanPdcDetailsWithAmortization>(pdc => {
      return { ...pdc, amortization: _.find(this.loan.amortizationSchedule.list, a => a.id === pdc.amortizationId) };
    });
    console.debug('Finish updating pdcList');
  }

  onPostModalReady = ({ api }: {api: ModalApi}) => {
    this.postModal = api;
  };

  onCancelModalReady = ({ api }: {api: ModalApi}) => {
    this.cancelModal = api;
  };

  async onPost(): Promise<void> {
    const { accepted } = await this.postModal?.show();

    if (accepted) {
      try {
        const selectedPDC: LoanPdcDetailsWithAmortization = this.pdcList.find(pdc => pdc.id == this.selectedPdcId)!;
        let commandName: string;
        let request: unknown;
        if (selectedPDC.type === 'ON_US') {
          commandName = 'PayLoanByPostDatedOnUsCheck';
          request = this.buildPostDatedOnUsCheckPaymentRequest(selectedPDC);
        } else {
          commandName = 'EncashLoanPDC';
          request = { pdcId: this.selectedPdcId, ...this.postRequest }
        }

        const { output, approvalRequired }= await this.command.execute<unknown, LoanPaymentOutput | LoanPaymentOutputWithErrors>(commandName, request).toPromise();
        if (!approvalRequired) {
          const errors = (<LoanPaymentOutputWithErrors> output).errors;
          if (!_.isEmpty(errors)) {
            const errorMsg: string = `Payment was unsuccessful due to following errors:<br>${ errors.join('<br>') }`;
            this.popup({ header: 'Error', text: errorMsg, renderHtml: true });
          }
        }
      } finally {
        this.postRequest = {};
        // when PDC encashment fails, there is a possibility that some additional
        // operations were registered, so cache should be refetched
        const [pdcList, ] = await Promise.all([
          this.customerCache.loanPDCs(this.loan.customerId, this.loan.id).refetch(),
          this.customerCache.loans(this.loan.customerId).refetch(),
          this.authentication.permissions['CST_CREDIT_LINE_READ'] ?  this.customerCache.creditLines(this.loan.customerId).refetch() : Promise.resolve(),
          this.customerCache.depositAccounts(this.loan.customerId).refetch(),
        ]);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.updatePdcList(pdcList);
      }
    }
    this.postRequest = {};
  }

  private buildPostDatedOnUsCheckPaymentRequest(pdc: LoanPdcDetailsWithAmortization): unknown {
    const checkInput: unknown = {
      productId: this.loan.id,
      customerId: this.loan.customerId,
      bankId: pdc.bankId,
      amount: pdc.amount,
      entryType: 'CREDIT',
      closeOperation: false,
      validFrom: pdc.validFrom,
      remarks: 'Loan payment with PDC',
      micrNumber: pdc.micrNumber,
      checkNumber: pdc.micrNumber.substr(0, 10),
      onUsCheck: true
    };

    return {
      productId: pdc.loanId,
      amount: pdc.amount,
      priorityAmortizationIds: [pdc.amortizationId],
      officialReceipt: this.postRequest.officialReceipt,
      temporaryReceipt: this.postRequest.temporaryReceipt,
      pdcId: pdc.id,
      checkOperation: checkInput
    };
  }

  async onCancel(): Promise<void> {
    const { accepted } = await this.cancelModal?.show();

    if (accepted) {
      await this.command.execute('CancelLoanPDC', { pdcId: this.selectedPdcId, remarks: this.cancelRemarks }).toPromise();
      this.customerCache.loanPDCs(this.loan.customerId, this.loan.id).refetch();
      this.$route.reload();
    }
    this.cancelRemarks = undefined;
  }

  getStatusStyle(status: LoanPDCStatus): string {
    return getStatusStyle(status);
  }
}

nxModule.component('loanPdcTab', {
  templateUrl,
  controller: LoanPdcTab,
  bindings: {
    loan: '<'
  }
});
