import {AgentTransaction} from "components/agent-banking/agent.types";

export const cycleType = Object.freeze([{
  label: 'Day',
  value: 'DAY'
}, {
  label: 'Week',
  value: 'WEEK'
}, {
  label: 'Month',
  value: 'MONTH'
}, {
  label: 'Month End',
  value: 'MONTH_END'
}, {
  label: 'Quarter',
  value: 'QUARTER'
}, {
  label: 'Quarter End',
  value: 'QUARTER_END'
}, {
  label: 'Year',
  value: 'YEAR'
}]);


export const pledgeAgreementCycleTypes = Object.freeze([{
  label: 'Quarter',
  value: 'QUARTER'
}, {
  label: 'Quarter End',
  value: 'QUARTER_END'
}]);

export const pledgePaymentIntervals = [{
  label: 'Weekly',
  value: 'WEEKLY'
}, {
  label: 'Monthly',
  value: 'MONTHLY'
}, {
  label: 'Monthly (last day of month)',
  value: 'MONTHLY_LAST_DAY'
}, {
  label: 'Every 30 days',
  value: 'EVERY_30_DAYS'
}, {
  label: 'Semimonthly (1.X & 16.X)',
  value: 'SEMIMONTHLY_1_16'
}, {
  label: 'Semimonthly (15.X & 30.X)',
  value: 'SEMIMONTHLY_15_30'
}, {
  label: 'Semimonthly (rel. grant date)',
  value: 'SEMIMONTHLY_GRANT_DATE'
}, {
  label: 'Quarterly',
  value: 'QUARTERLY'
}];


export const depositGroupNames = Object.freeze([{
  label: 'Time deposit',
  value: 'TD'
}, {
  label: 'Special savings account',
  value: 'SSA'
}]);

export const initialDepositOperations = [
  {
    label: 'Cash Deposit',
    value: 'DepositCashToAccount'
  },
  {
    label: 'Check Deposit',
    value: 'DepositCheckToAccount'
  },
  {
    label: 'Credit Memo',
    value: 'DepositMemoToAccount'
  },
  {
    label: 'Transfer To Product',
    value: 'AccountTransferToProduct'
  },
  {
    label: 'Credit On-Us Check',
    value: 'CreditOnUsCheck'
  },
  {
    label: 'Release By Credit To Account',
    value: 'ReleaseLoanByCreditToAccount'
  },
  {
    label: 'Term Deposit Interest Credit',
    value: 'InterestsTransferToProduct'
  },
  {
    label: 'ATM Fund Transfer',
    value: 'AtmFundTransfer'
  },
  {
    label: 'ATM Interbank Deposit',
    value: 'AtmInterbankFundTransferDeposit'
  },
  {
    label: 'Account Partner Deposit',
    value: 'AccountPartnerDeposit'
  },
  {
    label: 'Tagged Credit Memo',
    value: 'AccountTaggedCreditMemo'
  },
  {
    label: 'InstaPay fund transfer',
    value: 'ProcessIncomingInstapayTransfer'
  }
];

export const agentTransactions = [
  {
    label: 'Agent cash deposit',
    value: AgentTransaction.AGENT_ACCOUNT_CASH_DEPOSIT
  },
  {
    label: 'Agent cash withdrawal',
    value: AgentTransaction.AGENT_ACCOUNT_CASH_WITHDRAWAL
  },
  {
    label: 'Agent balance inquiry',
    value: AgentTransaction.AGENT_ACCOUNT_BALANCE_INQUIRY
  },
  {
    label: 'Bills payment',
    value: AgentTransaction.BILL_PAYMENT
  },
  {
    label: 'Top up payment',
    value: AgentTransaction.TOP_UP_PAYMENT
  },
  {
    label: 'Prepaid payment',
    value: AgentTransaction.PREPAID_PAYMENT
  },
]

export const maturityStrategyMapping: Record<string, string> = {
  'NONE': 'Wait for customer arrival',
  'TRANSFER_TO_ACCOUNT': 'Auto transfer to savings account',
  'RENEW_WITH_INTEREST': 'Auto renewal with interests',
  'RENEW_WITHOUT_INTEREST': 'Auto renewal w/o interests',
};

export const creditingStrategyMapping: Record<string, string> = {
  'NOT_ALLOWED': 'Not allowed',
  'CASH_WITHDRAWAL': 'Cash on arrival',
  'CHECK_WITHDRAWAL': 'Check on arrival',
  'ADHOC_WITHDRAWAL': 'Cash or check on arrival',
  'PDC_WITHDRAWAL': 'Post-dated checks on opening',
  'PRODUCT_TRANSFER': 'Product transfer',
  'COMPOUNDED': 'Compounded to principal'
};

export const depositDocStampCalculationMethods = Object.freeze([
  'PERCENTAGE_OF_TOTAL_BALANCE',
  'ROUNDED_PERCENTAGE_OF_TOTAL_BALANCE_WITH_CUSTOM_DIVISOR'
])

export const depositCustomCalculationMethods = Object.freeze([
  'PERCENTAGE_OF_DUE_AND_OVERDUE_PLEDGE_AMOUNT'
])
