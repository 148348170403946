import {IScope} from 'angular';
import BigNumber from 'bignumber.js';
import {ActionCategory} from 'components/administration/transactions/action-category.types';
import {Unit} from 'components/general-ledger/common/gl-category-input.component';
import {CustomerCache} from 'components/service/customer.cache.types';
import {AttachedFile, FileService} from 'components/service/file.service';
import {Account} from 'components/service/product.types';
import {NxIFilterService} from 'components/technical/angular-filters';
import {CsvSeparator, csvSeparators} from 'constants/csvSeparators';
import nxModule from 'nxModule';
import {Confirmation} from 'shared/common/confirmation.types';
import Popup from 'shared/common/popup';
import {CommandService} from 'shared/utils/command/command.types';
import Notification from 'shared/utils/notification';
import '../common/account-picker.component';
import './batch-memo.less';
import templateUrl from './batch-memo.template.html';

type TagType = 'NONE' | 'PARTNER_LOAN_RELEASE';
type EntryType = 'DEBIT' | 'CREDIT';
type DisplayMode = EntryType | 'HISTORY';
type DataSourceType = 'CSV_FILE' | 'MANUAL';

interface BatchMemoEntry {
  productNumberOrExtraNumber: string;
  amount: number;
  remarks: string;
}

type AccountBatchMemoEntry = Account & Pick<BatchMemoEntry, 'amount' | 'remarks'>;

interface AccountBatchMemoInput {
  type: EntryType;
  categoryId: number;
  remarks: string | null;
  allowNonActiveAccounts: boolean;
  tagType: TagType;
  accounts: BatchMemoEntry[];
  fileId?: number;
  csvSeparator?: string;
}

type BatchEntryRejection = BatchMemoEntry & { lineNo: number, reason: string }

interface AccountBatchMemoOutput {
  rejectedEntries: BatchEntryRejection[];
}

class BatchMemoComponent {
  readonly dataSourceTypes: { label: string, value: DataSourceType }[] = [
    {
      label: 'CSV file',
      value: 'CSV_FILE'
    },
    {
      label: 'Manual',
      value: 'MANUAL'
    }
  ];
  readonly tagTypes: { label: string, value: TagType }[] = [
    {
      label: 'None',
      value: 'NONE'
    },
    {
      label: 'Partner loan release',
      value: 'PARTNER_LOAN_RELEASE'
    }
  ];

  displayMode: DisplayMode = 'CREDIT';
  tagType: TagType = 'NONE';
  allowNonActiveAccounts: boolean = true;
  remarks?: string;

  dataSource: DataSourceType = 'MANUAL';
  memoEntries: AccountBatchMemoEntry[] = [];
  csvSeparators: ReadonlyArray<CsvSeparator> = csvSeparators;
  selectedCsvSeparator?: string = csvSeparators[0].value;
  fileContent: AttachedFile[] = [];

  total: number = 0;

  categoryUnits?: Unit[];
  category?: ActionCategory;

  constructor(private readonly $scope: IScope,
              private readonly $filter: NxIFilterService,
              private readonly command: CommandService,
              private readonly confirmation: Confirmation,
              private readonly popup: Popup,
              private readonly customerCache: CustomerCache,
              private readonly fileService: FileService,
              private readonly notification: Notification) {
  }

  getFileId(): number | undefined {
    return this.fileContent.length === 1 ? this.fileContent[0].id : undefined;
  }

  setDisplayMode(displayMode: DisplayMode): void {
    this.displayMode = displayMode;
  }

  addMemoEntry(memoEntry: AccountBatchMemoEntry): void {
    this.memoEntries.push(memoEntry);
    this.updateTotal();
  }

  removeMemoEntry(index: number): void {
    this.memoEntries.splice(index, 1);
    this.updateTotal();
  }

  calculateTotal(entries: AccountBatchMemoEntry[]): number {
    const total: string = entries.map(item => item.amount)
    .reduce(
      (sum, amount) => sum.plus(amount || 0),
      new BigNumber(0)
    ).toFixed(2);

    if (entries.length > 0) {
      return Number(total);
    } else {
      return 0;
    }
  }

  private async deleteFile(fileId: number): Promise<void> {
    try {
      await this.fileService.deleteFile(fileId).toPromise();
      this.notification.show('Success', 'File deleted');
    } catch (e) {
      this.notification.show('Error', 'Error deleting file');
      console.error('Error deleting file', e);
    }
    this.fileContent = [];
  }

  async doOperation(): Promise<void> {
    const isConfirmed: boolean = await this.confirmation(this.createConfirmMsg());
    if (!isConfirmed) {
      return;
    }

    const request: Partial<AccountBatchMemoInput> = {
      type: <EntryType>this.displayMode,
      remarks: this.remarks,
      allowNonActiveAccounts: this.allowNonActiveAccounts,
      accounts: this.memoEntries.map(a => ({
        productNumberOrExtraNumber: a.productNumber,
        amount: a.amount,
        remarks: a.remarks
      })),
      fileId: this.getFileId(),
      csvSeparator: this.selectedCsvSeparator,
      categoryId: this.category?.id,
      tagType: this.tagType
    };

    const response = await this.command.execute<Partial<AccountBatchMemoInput>, AccountBatchMemoOutput>('AccountBatchMemo', request).toPromise();
    if (response.approvalRequired) {
      return;
    }

    this.resetAll();

    const errors = response.output.rejectedEntries;
    if (errors?.length > 0) {
      this.popup({
        header: 'Memo errors',
        text: this.createHtmlErrorMsg(errors),
        renderHtml: true
      });
    }
  }

  private createConfirmMsg(): string {
    const msg = [`Do you want to perform batch ${this.displayMode} memo`];
    if (this.getFileId()) {
      msg.push('using the uploaded file?');
    } else {
      msg.push(`for total sum of ${this.$filter('nxCurrency')(Number(this.total))}`);
    }
    return msg.join(' ');
  }

  private createHtmlErrorMsg(rejectedEntries: BatchEntryRejection[]): string {
    const errorDetails = rejectedEntries
    .map(e => `(Line No: ${e.lineNo}) <strong>${e.productNumberOrExtraNumber}</strong>: ${e.reason}<br>`)
    .join('');
    return `<p><span class="red"><strong>WARNING!</strong> Accounts listed below were omitted.</span></p>${errorDetails}`;
  }

  onCategoryChange(): void {
    const total = this.calculateTotal(this.memoEntries);
    this.categoryUnits = (this.category ? this.category.ledgerAccountFullCodes : [])
    .map(fullCode => ({
      fullCode,
      amount: total
    }));
  }

  async onDataSourceChange(): Promise<void> {
    const fileId = this.getFileId();
    this.resetSource();
    if (this.isManualDataSourceTypeSelected()) {
      if (fileId) {
        await this.deleteFile(fileId);
      }
    } else {
      this.selectedCsvSeparator = csvSeparators[0].value;
    }
  }

  isManualDataSourceTypeSelected(): boolean {
    return this.dataSource === 'MANUAL';
  }

  resetSource(): void {
    this.fileContent = [];
    this.memoEntries = [];
    this.updateTotal();
  }

  updateTotal(): void {
    this.total = this.calculateTotal(this.memoEntries);

    if (this.categoryUnits?.values()) {
      this.categoryUnits[0].amount = this.total;
    }
  }

  resetAll(): void {
    this.resetSource();
    this.tagType = 'NONE';
    this.category = undefined;
    this.categoryUnits = undefined;
    this.remarks = undefined;
  }
}

nxModule.component('batchMemo', {
  templateUrl: templateUrl,
  controller: BatchMemoComponent
});
