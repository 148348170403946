import nxModule from 'nxModule';
import moment from 'moment';
import _ from 'lodash';
import {NgTableParams} from 'ng-table';

import templateUrl from './custom-report.template.html';
import './custom-report.component.less';
import {IScope} from "angular";
import {HttpService} from "shared/utils/httpService";
import Popup from "shared/common/popup";
import {CustomReportSaved} from "components/service/custom-report/custom-report-saved.cache.types";
import {SchemaCache} from "components/service/custom-report/schema-tables.cache.types";
import ReportService from "components/report/report.service";
import {CustomReportHistory} from "components/service/custom-report/custom-report-history.cache.types";

interface Item {
  name: string;
  date: string;
  sql: string;
}

class CustomReport {
  readonly title: string = 'Custom reports editor';

  query: string = '';
  populateQuery: string = '';
  reportName: string = '';
  hints: any;
  onQueryUpdate: any;
  reportContent: any;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  savedTable: NgTableParams<Item>;
  customReportSavedList: Item[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  historyTable: NgTableParams<Item>;
  customReportHistoryList: Item[] = [];

  constructor(private readonly $scope: IScope,
              private readonly http: HttpService,
              private readonly customReportSaved: CustomReportSaved,
              private readonly customReportHistory: CustomReportHistory,
              private readonly reportService: ReportService,
              private readonly schemaCache: SchemaCache,
              private readonly popup: Popup) {
  }

  async $onInit(): Promise<void> {
    this.savedTable = new NgTableParams({}, {
      counts: [],
      getData: () => this.customReportSavedList
    });

    this.historyTable = new NgTableParams({}, {
      counts: [],
      getData: () => this.customReportHistoryList
    });

    this.onQueryUpdate = (payload: {sql: string}) => {
      this.query = payload.sql;
    };

    this.refreshHistory();
    this.updateSaved();
    this.hints = await this.getHints();
  }

  async getHints(): Promise<Record<string, string[]>> {
    const hints = await this.schemaCache.toPromise();
    const hintsFormatted: Record<string, string[]> = {};
    hints.forEach(val => hintsFormatted[`${val.schemaName}.${val.tableName}`] = val.columnNames);
    return hintsFormatted;
  }

  async runQuery(): Promise<void> {
    if (this.isQueryEmpty()) {
      this.popup({header: 'Error', text: 'There is no query to execute!'});
      return;
    }

    this.reportContent = await this.reportService.downloadJson({
      reportCode: 'CustomReport',
      params: {
        reportName: this.reportName,
        query: this.query
      }
    });

    this.addQueryToHistory();
  }

  async downloadXLS(): Promise<void> {
    if (this.isQueryEmpty()) {
      this.popup({header: 'Error', text: 'There is no query to save!'});
      return;
    }

    await this.reportService.downloadXls({
      reportCode: 'CustomReport',
      params: {
        reportName: this.reportName,
        query: this.query
      },
      reportName: this.reportName === '' ? `custom_report_${moment().valueOf()}.xlsx` : `${this.reportName}.xlsx`
    });
  }

  isQueryEmpty(): boolean {
    return !this.query || _.trim(this.query) === '';
  }

  refreshHistory(): void {
    this.customReportHistoryList = this.customReportHistory.values().reverse();
    this.historyTable.reload();
  }

  updateSaved(): void {
    this.customReportSavedList = this.customReportSaved.values();
    this.savedTable.reload();
  }

  addQueryToHistory(): void {
    this.customReportHistory.put(moment().valueOf(), {
      name: this.reportName,
      date: moment().format('YYYY-MM-DD, HH:mm'),
      sql: this.query
    });
    this.refreshHistory();
  }

  saveQuery(): void {
    if (!this.query) {
      this.popup({header: 'Error', text: 'There is no query to save!'});
      return;
    }

    this.customReportSaved.put(this.reportName, {
      name: this.reportName,
      date: moment().format('YYYY-MM-DD'),
      sql: this.query
    });
    this.updateSaved();
  }

  editQuery(item: Item): void {
    this.query = item.sql;
    this.populateQuery = this.query;
    this.reportName = item.name;
  }

  deleteSaved(item: Item): void {
    this.customReportSaved.remove(item.name);
    this.updateSaved();
  }
}

nxModule.component('customReport', {
  templateUrl,
  controller: CustomReport
});