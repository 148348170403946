import nxModule from 'nxModule';
import _ from 'lodash';

const templateUrl = require('./waive-amortization.template.html');
nxModule.component('customerAmortizationWaive', {
  templateUrl: templateUrl,
  controller: function ($scope, $route, $location, $filter, http, command, dict, customerCache, confirmation) {
    let that = this;
    let customerId = $route.current.params['customerId'];
    that.loanId = Number($route.current.params['loanId']);
    that.amortizationId = Number($route.current.params['amortizationId']);
    that.amortizationIds = [];
    that.amortization = null;
    that.loan = null;

    that.waiveRequest = {};
    that.empty = {};
    that.maxValues = {};

    that.$onInit = async () => {
      const loans = await customerCache.loans(customerId).toPromise();
      that.loan = loans.find(loan => loan.id === that.loanId);
      if (that.amortizationId) {
        that.amortization = _.find(that.loan.amortizationSchedule.list, (a) => a.id === that.amortizationId);
        that.amortizationIds = [that.amortizationId];
        that.maxValues = {
          principal: that.amortization.principalBalance,
          interest: that.amortization.interestBalance,
          cbuCharge: that.amortization.cbuChargeBalance,
          pfCharge: that.amortization.pfChargeBalance,
          tpCharge: that.amortization.tpChargeBalance,
          pastDueInterest: that.amortization.pastDueInterestBalance,
          pastDueMaturityInterest: that.amortization.pastDueMaturityInterestBalance,
          penalty: that.amortization.penaltyBalance,
          penaltyMaturity: that.amortization.penaltyMaturityBalance
        };
      } else {
        that.maxValues = {...that.loan.amortizationSchedule.totalBalance};
      }
    };

    that.getMessage = () => {
      const total = _.sumBy(that.waiveRequest.waiveParts, 'waiveAmount');
      return `Do you want to waive ${$filter('nxCurrency')(total)}?`;
    };

    that.redirectBack = () => $location.path(`/customer/${customerId}/loans/${that.loanId}`);

    that.pay = () => {
      confirmation(that.getMessage(), () => {
        command.execute('WaiveLoan', that.waiveRequest, {
          nxLoaderText: 'Performing operation...'
        }).success(() => {
          customerCache.loans(customerId).refetch();
          customerCache.customerProductFees(customerId).refetch();
          that.redirectBack();
        }).offline(() => {
          customerCache.loans(customerId).refetch();
          customerCache.customerProductFees(customerId).refetch();
          that.redirectBack();
        })
      });

    }
  }
});
