import nxModule from 'nxModule';
import angular from 'angular';
import {loanArbitraryFees} from 'constants/loan';

import './custom-fee-list.style.less';
import templateUrl from './custom-fee-list.template.html';

class CustomFeeList {

  addNewFeeDefinition() {
    let defaultValues = {};
    if (this.productGroup === 'LOAN') {
      defaultValues.applyOnStatuses = ['INACTIVE', 'ACTIVE'];
      defaultValues.calculateOnStatuses = ['INACTIVE', 'ACTIVE'];
      defaultValues.calculateOn = 'CREATE';
      defaultValues.applyOn = 'LOAN_RELEASE';
      defaultValues.displayOnProductCreation = true;
    } else if (this.productGroup === 'DEPOSIT') {
      defaultValues.applyOnStatuses = ['ACTIVE'];
      defaultValues.calculateOnStatuses = ['ACTIVE'];
      defaultValues.calculateOn = 'DEPOSIT_PRETERMINATION';
      defaultValues.applyOn = 'DEPOSIT_PRETERMINATION';
    }

    let newFeeDefinition = {
      calculationOrder: 1,
      feeClass: 'CUSTOM',
      feeType: 'FIXED',
      calculationMethod: 'FIXED_AMOUNT',
      operationGroup: 'APPLY_FEE',
      //defaultValues
      feeName: '',
      enabled: true,
      fixedAmount: 0,
      calculateOn: 'CREATE',
      applyOn: undefined,
      overdraftStrategy: 'OVERDRAFT',
      roundingScale: 'CENTAVO',
      ...defaultValues
    };
    this.feeDefinitions.push(newFeeDefinition);
    this.editFeeDefinition(newFeeDefinition, false);
  }

  editFeeDefinition(feeDefinition, storeOldValue = true) {
    this.selectedFeeForEdit = feeDefinition;
    if (storeOldValue) {
      this.feeDefinitionOldValues = angular.copy(feeDefinition);
    }
    if ((loanArbitraryFees.includes(feeDefinition.feeClass) && feeDefinition.applyOn === 'LOAN_RELEASE' || ['PENALTY', 'PAST_DUE_INTEREST'].includes(feeDefinition.feeClass))
      && feeDefinition.feeType === 'ARBITRARY') {
      feeDefinition.feeType = feeDefinition.calculationMethod === 'FIXED_AMOUNT' ? 'FIXED' : 'PERCENTAGE';
    }
    //settings this value will hide the cancel/save buttons from parent controller
    this.form.wizardMode = true;
  }

  removeFeeDefinition(index) {
    this.feeDefinitions.splice(index, 1);
    this.form.$setDirty();
    if (this.onChange) this.onChange();
  }

  cancelFeeDefinitionEdition() {
    if (this.feeDefinitionOldValues) {
      Object.assign(this.selectedFeeForEdit, this.feeDefinitionOldValues);
    } else {
      // remove newly added fee definition
      this.feeDefinitions.pop();
    }
    this.closeCustomFeeController();
    if (this.onCancel) this.onCancel();
  }

  save() {
    this.closeCustomFeeController();
    if (this.onChange) this.onChange();
  }

  // feels like a request from some sort of a plane pilot
  closeCustomFeeController() {
    if (this.selectedFeeForEdit) {
      const fee = this.selectedFeeForEdit;
      if (loanArbitraryFees.includes(fee.feeClass) && fee.feeClass != 'DOC_STAMP' && ['FIXED', 'PERCENTAGE'].includes(fee.feeType) && fee.applyOn === 'LOAN_RELEASE') {
        fee.feeType = 'ARBITRARY';
      }
    }

    this.selectedFeeForEdit = null;
    this.feeDefinitionOldValues = null;
    //show the parent controller cancel/save buttons
    this.form.wizardMode = false;
  }

}

nxModule.component('customFeeList', {
  templateUrl,
  bindings: {
    form           : '=',
    feeDefinitions : '=',
    //DEPOSIT, LOAN
    productGroup   : '<',
    onCancel       : '&',
    onChange       : '&'
  },
  controller: CustomFeeList
});
