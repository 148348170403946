import {IScope} from 'angular';
import {CustomFieldCategory, CustomFieldDefinition, CustomFieldGroup} from 'custom-field/CustomFieldDefinitionTypes';
import customFieldService from 'custom-field/CustomFieldService';
import nxModule from 'nxModule';
import Authentication from 'shared/utils/authentication';
import templateUrl from './custom-field-input.template.html';

type CustomFieldDefinitionWithCategories = CustomFieldDefinition & {children: CustomFieldCategory[]};

class CustomFieldInput {

  group!: CustomFieldGroup;
  assignedCategoryIds!: number[];
  selectedCustomFieldCategory: Record<number, Partial<CustomFieldCategory>> = {};
  customFieldValues: Record<number, string> = {};
  availableCustomFields!: number[];
  customFieldCategoryDefinitions!: CustomFieldDefinitionWithCategories[];
  customFields!: CustomFieldDefinition[];
  branchId!: number;
  editMode!: boolean;

  constructor(private $scope: IScope,
              private authentication: Authentication) {
    $scope.$watchGroup(['$ctrl.availableCustomFields'], () => this.configureCustomFields());
  }

  async configureCustomFields(): Promise<void> {
    if (this.availableCustomFields || this.assignedCategoryIds) {
      const allDefinitions = await customFieldService.readDefinitions({groups: [this.group]});
      const allEnabled = allDefinitions.filter(c => c.enabled);

      const categoryDefinitions = this.getCategoryDefinitions(allEnabled);
      const categoryDefinitionsIds = categoryDefinitions.map(c => c.id).filter(id => id);
      const categoryValues = await customFieldService.readCategories(categoryDefinitionsIds);

      for (const definition of categoryDefinitions) {
        if (definition.id) {
          definition.children = categoryValues.filter(c => c.definitionId === definition.id && c.enabled);
        }
      }

      const definitionByCategoryMap = this.createDefinitionIdByCategoryMap(categoryDefinitions);

      this.customFieldCategoryDefinitions = categoryDefinitions.filter(root => this.isCategoryAvailable(root)
        || this.isCategoryAssigned(definitionByCategoryMap, root));

      if (this.assignedCategoryIds && this.customFieldCategoryDefinitions.length > 0) {
        for (const categoryId of this.assignedCategoryIds) {
          const definitionId = definitionByCategoryMap.get(categoryId);
          if (definitionId) {
            this.selectedCustomFieldCategory[definitionId] = {id: categoryId};
          }
        }
        this.selectedProductCategoryChange();
      }

      this.customFieldCategoryDefinitions = this.customFieldCategoryDefinitions.filter(c => {
        return (c.id && this.selectedCustomFieldCategory[c.id]) || this.isAvailableInBranch(c);
      });

      this.customFields = allEnabled.filter(c => c.type !== 'CATEGORY')
        .filter(c => !c.availableInBranchIds || c.availableInBranchIds.includes(this.branchId || this.authentication.context.branchId))
        .filter(c => (c.id && this.availableCustomFields.includes(c.id)));
    }
  }

  isAvailableInBranch(definition: CustomFieldDefinition): boolean {
    return !definition.availableInBranchIds || definition.availableInBranchIds.includes(this.branchId || this.authentication.context.branchId);
  }

  isCategoryAssigned(map: Map<number, number>, definition: CustomFieldDefinitionWithCategories): boolean {
    return this.assignedCategoryIds && this.assignedCategoryIds.some(c => map.get(c) === definition.id);
  }

  isCategoryAvailable(root: CustomFieldDefinitionWithCategories): boolean {
    return this.availableCustomFields && this.availableCustomFields.includes(root.id);
  }

  getCategoryDefinitions(allEnabledDefinitions: CustomFieldDefinition[]): CustomFieldDefinitionWithCategories[] {
    return allEnabledDefinitions.filter(
        c => c.type === 'CATEGORY')
      .map(c => {
        return {
          ...c,
          children: []
        };
      });
  }

  hasChildren(category: CustomFieldCategory): boolean {
    return category.children && category.children.length > 0;
  }

  createDefinitionIdByCategoryMap(definitions: CustomFieldDefinitionWithCategories[]): Map<number, number> {
    const map = new Map();
    definitions.forEach(d => this.persistLeafToMap(d.children, map));
    return map;
  }

  persistLeafToMap(categories: CustomFieldCategory[], map: Map<number, number>): void {
    for (const category of categories) {
      if (category.id && category.definitionId && !this.hasChildren(category)) {
        map.set(category.id, category.definitionId);
        continue;
      }
      this.persistLeafToMap(category.children, map);
    }
  }

  selectedProductCategoryChange(): void {
    this.assignedCategoryIds = Object.keys(this.selectedCustomFieldCategory)
      .map(k => Number(k))
      .filter(rootId => this.selectedCustomFieldCategory[rootId])
      .map(rootId => this.selectedCustomFieldCategory[rootId].id)
      .filter((n): n is number => !!n);
  }

  productCategoryLabel(category: CustomFieldCategory): string {
    return category.value;
  }

  showOnlyEnabled({node}: {node: {enabled: boolean}}): boolean {
    return node.enabled;
  }

  editModeEnabled(): boolean {
    return this.editMode;
  }
}

nxModule.component('customFieldInput', {
  templateUrl,
  bindings: {
    group: '<',
    availableCustomFields: '<',
    assignedCategoryIds: '=',
    customFieldValues: '=',
    branchId: '=',
    editMode: '='
  },
  controller: CustomFieldInput
});
