import 'rxjs/add/operator/filter';
import _ from 'lodash';

import nxModule from 'nxModule';
import templateUrl from './holiday-page.template.html';
import {setBreadcrumbs} from '../../../shared/utils/set-breadcrumbs';

nxModule.component('holidayPage', {
  templateUrl,
  bindings: {
    detailLink: '@'
  },
  controller: function($scope, $routeParams, breadcrumbs, http, branchService, confirmation, branchHolidayCache,
                       command) {
    const that = this;
    that.header = [];
    that.holidays = null;

    const branchId = $routeParams.branch;
    const requestParams = {
      branchId: parseInt(branchId),
      types: ['HOLIDAY', 'WEEKEND'],
      mode: 'ALL'
    };

    const fetchHolidays = async () => {
      that.holidays = await branchHolidayCache.withParam(requestParams).toPromise();
    };

    const subscription = branchService.toObservable()
      .map(branches => _.find(branches, {id: ~~branchId}))
      .subscribe(branch => {
        that.branch = branch;

        setBreadcrumbs(breadcrumbs, 'holidays-label', branch.name);

        that.header = [{
            label: 'Branch name',
            text: branch.name,
          }, {
            label: 'Branch code',
            text: branch.code,
          }
        ];
      });

    that.addHoliday = () => {
      that.holidays.push({branchId: parseInt(branchId), dirty: true});
    };

    that.cancelAddHoliday = () => {
      that.holidays.pop();
    };

    that.removeHoliday = idx => {
      that.holidays = _.reject(that.holidays, (value, index) => idx === index);
    };

    that.isSaveDisabled = (form) => form.$invalid && form.$submitted;

    that.saveHoliday = async (form, index) => {
      form.$setSubmitted();

      if(form.$valid) {
        const confirmed = await confirmation('Do you want to save holiday?');
        if (!confirmed) return;

        const req = {
          branchId: that.holidays[index].branchId,
          name: that.holidays[index].name,
          type: 'HOLIDAY',
          date: that.holidays[index].date
        };

        const response = await command.execute('CreateHoliday', req).toPromise();

        if (response && !response.approvalRequired) {
          that.holidays[index].dirty = false;
          that.holidays[index].id = response.output.id;
          that.holidays[index].types = [req.type];
          that.holidays[index].branchId = req.branchId;
          branchHolidayCache.withParam(requestParams).refetch();
        }
      }
    };

    that.deleteHoliday = async index => {
      const confirmed = await confirmation('Do you want to remove holiday?');
      if (!confirmed) return;

      const req = {
        id: `${that.holidays[index].id}`
      };

      const response = await command.execute('DeleteHoliday', req).toPromise();

      if (response && !response.approvalRequired) {
        that.holidays.splice(index, 1);
        branchHolidayCache.withParam(requestParams).refetch();
      }
    };

    that.$onInit = () => {
      fetchHolidays();
    };

    that.$onDestroy = () => {
      subscription.unsubscribe();
    }
  }
});